import axios from "axios";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import MyModal from "./MyModel";
import { SWIPELINC_API } from "../../../utils/constant";
import { Container, Card, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import RejectionModal from "./RejectionModal";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import InactiveServices from "../../InactiveServices";

function Withdrawl({ setShowLoader }) {
  const [withdrawData, setWithdrawData] = useState([]);
  const [callAgain, setCallagain] = useState(false);

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [type, setType] = useState(false);
  const [remark, setRemark] = useState("");

  const hasPrivilage=useCheckPrivilages(2708)

  const handleClick = (id, type) => {
    const selectedWithdrawal = getSelectedWithdrawalData(id);

    if (
      selectedWithdrawal.isDepositeForPayout === false ||
      selectedWithdrawal.isDepositeForPayout === null
    ) {
      setShowModal(true);
      setSelectedId(id);
      setType(type);
    } else {
      performApprovalOrRejection(id, type);
    }
  };

  const getSelectedWithdrawalData = (selectedId) => {
    const selectedWithdrawal = withdrawData.find(
      (item) => item.withdrawalId === selectedId
    );

    return selectedWithdrawal;
  };

  const performApprovalOrRejection = (id, type) => {
    const selectedWithdrawal = getSelectedWithdrawalData(id);
    console.log(type ,selectedWithdrawal)
    
    if (selectedWithdrawal.isDepositeForPayout === false) {
      setShowModal(true);
      setSelectedId(id);
      setType(type);
    } else {
      const RETURNPAYLOAD = (type) => {
        if (!type) {
 
          return {
            transactionDate: selectedWithdrawal.transactionDate,
            utr: selectedWithdrawal.utr,
            status: "success",
            withdrawalId: id,
            remark: "",
          };
        } else if (type) {
          return {
            transactionDate: "",
            utr: "",
            status: "reject",
            withdrawalId: id,
            remark: remark,
          };
        }
      };
 
      const requestData = RETURNPAYLOAD(type);

      
    
      axios
        .put(
          `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/updateWithdrawal`,
          requestData,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            swal({
              title: "Success",
              text: type
                ? "Your application has been rejected."
                : "Your application has been approved.",
              icon: "success",
            });
            setCallagain(true);
          } else {
          }
        })
        .catch((error) => {
          console.error("Error performing approval/rejection:", error);
        });
    }
  };

  

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllPandingWithdrawal`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setWithdrawData(response.data.data);
        } else {
          setWithdrawData([]);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        console.error("Error fetching withdrawal data:", error);
      });
  }, [showModal, callAgain]);

  const columns = [
    { label: "Merchant Id", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "fullName", width: "auto" },
    { label: "Email Id", field: "emailId", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    {label: "Virtual Account Type",field: "virtualAccountType",width: "auto", },
    {label: "Virtual Account Number",field: "virtualAccountNumber",sort: "asc", width: "auto" },
    {label: "Virtual IFSC Code",field: "ifsc",sort: "asc",width: "auto"  },
    {label: "Bank Name",field: "bankName",sort: "asc",width: "auto"},
    { label: "Status", field: "status", width: "auto" },
    { label: " Created Date", field: "createDate", width: "auto" },
    { label: " Created Time", field: "createTime", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    // { label: "UTR", field: "UTR", width: "auto" },
    { label: "Action", field: "action", width: "auto" },
  ];
  
  const mappedData = withdrawData
    ? withdrawData.map((item) => {
        const mappedItem = { ...item };
        mappedItem.action = (
          <div className="d-flex gap-2">
            <button
              className="btn btn-success"
              onClick={() => handleClick(item.withdrawalId, false)}
            >
              Approve
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleClick(item.withdrawalId, true)}
            >
              Reject
            </button>
          </div>
        );
        return mappedItem;
      })
    : [];

  return (
    <>
    {/* {hasPrivilage ? (  */}
       <Container>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4">
              <Card.Body>
                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      hover
                      striped
                      small
                      bordered
                      responsive
                      sortable={true}
                      searching={true}
                      entries={10}
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      footer="none"
                      theadColor="#333"
                      paginationLabel={["Previous", "Next"]}
                    />

                    <MyModal
                      showModal={showModal}
                      setShowModal={setShowModal}
                      withdrawalData={getSelectedWithdrawalData(selectedId)}
                      id={selectedId}
                      type={type}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* ) : (<InactiveServices location={"privilages"}/>)} */}
     
    </>
  );
}

export default Withdrawl;
