import React, { useEffect, useState } from "react";
import axios from "axios";
import Popup from "../components/Popup";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../stylesheet/ViewDetails.css";

const MoaDetails1 = ({ id,handleClose }) => {
  const [merchantData, setMerchantData] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewDataClicked, setViewDataClicked] = useState(false);
  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [MerchantId, setMerchantId] = useState("");
  const token = localStorage.getItem("token");
  const handleClosePDF = () => {
    window.close();
  };

  useEffect(() => {
    // setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/${id}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        })
      .then((response) => {
        setMerchantData(response?.data?.data);
        setMerchantId(response?.data?.data?.mid)
        console.log(response.data.data.mid)
        handleViewData(MerchantId)
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error.message);
        // setShowLoader(false);
      });

  }, [id]);

  const handleViewData = (MerchantId) => {
    axios.get(
      `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/viewData/${MerchantId}`,
      {
        headers: {
          Authentication: `Bearer ${token}`,
        }
      }
    )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setPdfFilenames(response?.data?.data);
          setViewDataClicked((prev) => !prev);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  const handleViewPDF = (filename) => {
    const encodedFilename = encodeURIComponent(filename);
    const headers = {
      Authentication: `Bearer ${token}`,
    };

    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${encodedFilename}`,
        {
          responseType: "arraybuffer",
          headers,
        }
      )
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        // setSelectedFile(pdfUrl);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error.message);
      });
  };
  return (
    <>
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    <div className="vertical-table">
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Company Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.companyName}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Master
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.masterMerchant}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Authorized Person Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.authorizedPersonName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          e-Mail ID
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.email}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Mobile Number
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.mobile}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Payment Services
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          <ul>
                            {merchantData?.paymentService?.map((service, index) => (
                              <li key={index}>
                                {service.paymentService}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Website URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.websiteUrl}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Payin Callback URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.callBackUrl}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Payout Callback URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.payoutCallbackUrl}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Chargeback CallBack URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.chargebackCallbackUrl}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Business Type
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.companyType}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Business Categories
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.businessType}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Bank Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.merchantBankName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Account Number
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.merchantAccountNumber}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant IFSC Code
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.merchantIfscCode}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Mode of Registration
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.modeOfRegistration}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Referred By
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.referredBy}
                        </div>
                      </div>
                    </div>

                    {viewDataClicked && (
                      <div className="pdf-list-container report-grid-view">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Select</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pdfFilenames?.map((fileObject, index) => (
                              <tr key={index}>
                                {/* <td>{fileObject["Orignal name"]}</td> */}
                                <td>{fileObject["File name"]}</td>
                                <td>
                                  <button
                                    className="btn btn-secondary"
                                    onClick={() =>
                                      handleViewPDF(fileObject["Orignal name"])
                                    }
                                  >
                                    View PDF
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="button-toolbar groups-btn text-end">
                      <button
                        className="btn btn-warning"
                        onClick={() => handleViewData(merchantData.mid)}
                      >
                        {viewDataClicked ? "View Data" : "View Data"}
                      </button>
                      <button type="button" class="btn btn-primary" onClick={handleClose}>
                        Close
                      </button>

                    </div>
                  </div>

                </Col>
              </Row>

            </Card.Body>
          </Card>
        </Container>
      </section>

      {/* {open && <Popup message={message} open={open} close={setOpen} />} */}
      {selectedFile && (
        <div className="pdf-viewer">
          <iframe src={selectedFile} title="PDF Viewer" />
          <button onClick={handleClosePDF}>Close</button>
        </div>
      )}

    </>
  );
};

export default MoaDetails1;