import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTable } from 'mdbreact';
// import { useNavigate } from "react-router-dom"; 
// import queryString from "query-string";
import { SWIPELINC_API } from "../utils/constant";
import ViewFile from "./ViewFile";
import ViewFileYesBank from "./ViewFileYesBank";

const ViewSubmittedApplicationsYesBank = ({setShowLoader}) => {
  const [applications, setApplications] = useState([]);
  const [dataApplication, setDataApplication] = useState([]);
  const [showPaymentServices, setShowPaymentServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [openView,setOpenView] = useState(false);
  const [id, setId] = useState("");
  
  const token = localStorage.getItem("token");
  const handleSelect = (registrationId) => {
    setShowPaymentServices((prevShowPaymentServices) => ({
      ...prevShowPaymentServices,
      [registrationId]: !prevShowPaymentServices[registrationId],
    }));
  };

  // const navigate = useNavigate();
  const fetchSubmittedApplications = () => {
    setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/yesBank/getAllYesOnBoard`, {
        headers: {
          Authentication: `Bearer ${token}`
        },
        
      })
      .then((response) => {
        if (response?.data?.statusCode === 200 || response.data.responseCode === 304) {
          setApplications(response?.data?.data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchSubmittedApplications();
  }, [openView]);
  

  const handleViewDetails = (data) => {
    
    setId(data?.registrationId);
    setDataApplication(data)
    
    setSelectedServices({ ...selectedServices, [data.registrationId]: showPaymentServices[data.registrationId] });
    setOpenView(true)
  };

  const handleClose = () => {
    setId("");
    setSelectedServices({});
    setOpenView(false)
  };
  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Authorized Person Name", field: "authorizedPersonName", width: "auto" },
    { label: "e-Mail ID", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Company PAN Number", field: "companyPanNumber", width: "auto" },
    { label: "GST Number", field: "gstNumber", width: "auto" },
    { label: "Services", field: "services", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = applications?.map((application) => {
      const mappedItem = { ...application };
      mappedItem.services = (
        <div>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => handleSelect(application.registrationId)}
          >
            {showPaymentServices[application.registrationId]
              ? "Hide Services"
              : "View Services"}
          </button>
          {showPaymentServices[application.registrationId] && (
            <div>
              {application?.paymentService?.map((service) => (
                <div key={service.paymentTypeId}>
                  {service.paymentService}
                </div>
              ))}
            </div>
          )}
        </div>
      );
      mappedItem.action = (
        <button className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleViewDetails( application)}
        >
          View Details
        </button>
      );
      return mappedItem;
    })
  
return (
    <>
      <div className="report-grid-view">
     {!openView ? (
        <MDBDataTable
          className='dataTable'
          striped
          small
          bordered
          hover
          responsive
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          entries={50}
          exportToCSV
          theadColor="#333"
          paginationLabel={['Previous', 'Next']}
          sortable={true}
          footer="none"
        />
      ): openView ?(
      <ViewFileYesBank
      id = {id}
      data={dataApplication}
      handleClose = {handleClose}

      />
      ) :null }
      </div>
    </>
  );
};

export default ViewSubmittedApplicationsYesBank;