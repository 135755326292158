import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import {
  fetchAllMerchantMaster,
  fetchAllSIDMasterSubmittedApplications,
  fetchAllVehicleMasterForm,
} from "../utils/ApiServices";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import NumberInWords from "./NumberInWords";

const SubmittedMasterSIDApplications = ({ setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [sidDetailsData, setSidDetailsData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [disableID, setDisableID] = useState("");

  const [editFormData, setEditFormData] = useState({
    sid: "",
    txnAmt: "",
    mid: "",
    merchantName: "",
    id: "",
    vehicleMasterId: "",
    masterMerchantId: "",
    masterMerchantName: "",
  });

  const getAllMerchantMaster = async () => {
    try {
      const response = await fetchAllMerchantMaster(token); // Add the await keyword here

      // console.log(">>>>>", response.data);

      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllVehicle = async () => {
    try {
      const response = await fetchAllVehicleMasterForm(token); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMerchantMaster();
    getAllVehicle();
  }, []);

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "company", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "Vehicle Name", field: "vehicleName", width: "auto" },
    { label: "Merchant Master", field: "masterMarchantName", width: "auto" },
    {
      label: "Maximum Transaction Amoumt",
      field: "maxTxnAmount",
      width: "auto",
    },
    { label: "Action", field: "action", width: "auto" },
  ];

  const openDetails = (
    mid,
    company,
    sid,
    maxTxnAmount,
    id,
    vehicleMasterId,
    masterMerchantId,
    masterMerchantName,
  ) => {
    console.log(
     "mid----" ,mid,
     "company----" ,company,
     "sid----" ,sid,
     "mx----" ,maxTxnAmount,
     "id----" ,id,
     "vMasterId----" ,vehicleMasterId,
     "mmid----" ,masterMerchantId,
     "mmName----" ,masterMerchantName,
    );
    setEditFormData({
      txnAmt: maxTxnAmount,
      mid,
      merchantName: company,
      sid,
      id,
      vehicleMasterId: vehicleMasterId,
      masterMerchantId: masterMerchantId,
      masterMerchantName: masterMerchantName,
    });
    console.log(editFormData);
    setIsModalOpen(true);
  };

  const sidDetailsColumn = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "Vehicle Master ID", field: "vehicleMasterId", width: "auto" },
    { label: "Transaction Limit", field: "sidLimit", width: "auto" },
  ];
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseModal2 = () => {
    setIsModal2Open(false);
  };
  const handelSearchLimit = (e, sid, id) => {
    setDisableID(id);
    axios
      .get(
       ` ${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getall-mapped-sid/${sid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.statusCode === 200) {
          setSidDetailsData(response.data.data);
          isModal2Open(false);
        } else if (response.data.statusCode === 306) {
          setSidDetailsData([]);
        } else {
          setSidDetailsData([]);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setIsModal2Open(true);
  };

  const disableSID = () => {
    const formData = new FormData();
    formData.append("id", disableID);
    setShowLoader(true);
    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/disableSid/${disableID}`,
        "",

        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
          setEditFormData({
            sid: "",
            txnAmt: "",
            mid: "",
            merchantName: "",
            id: "",
            vehicleMasterId: "",
            masterMerchantId: "",
          });
          handleCloseModal2();
          FetchData(token);
          setDisableID("");
          isModal2Open(false);
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  const handelDataChange = (e) => {
    if (e.target.name === "vehicleMasterId") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-masterName");
      const masterId = selectedOption.getAttribute("data-masterId");

      console.log( "master---------------",master);
      

      setEditFormData({ ...editFormData, [e.target.name]: e.target.value , masterMerchantName: master ? master :'',
        masterMerchantId: masterId});

        console.log(editFormData)
    } else {
      setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
    }
    
  };

  const handleUpdateSubmit = (e) => {
    if (editFormData.merchantName === "") {
      swal({
        title: "Alert!",
        text: "Please Select Merchant",
        icon: "error",
      });
      return;
    }

    if (editFormData.sid === "") {
      swal({
        title: "Alert!",
        text: "Please Enter SID",
        icon: "error",
      });
      return;
    }

    if (editFormData.txnAmt === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Maximum Transaction Amount",
        icon: "error",
      });
      return;
    }
    if (editFormData.vehicleMasterId === "") {
      swal({
        title: "Alert!",
        text: "Please select vehicle",
        icon: "error",
      });
      return;
    }
    if (editFormData.masterMerchantId === "") {
      swal({
        title: "Alert!",
        text: "Please select merchant master",
        icon: "error",
      });
      return;
    }

    e.preventDefault();

    const payload = {
      company: editFormData.merchantName,
      maxTxnAmount: parseInt(editFormData.txnAmt),
      mid: editFormData.mid,
      sid: editFormData.sid,
      id: editFormData.id,
      vehicleMasterId: parseInt(editFormData.vehicleMasterId),
      masterMerchantId: parseInt(editFormData.masterMerchantId),
    };
    
    setShowLoader(true);
    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/UpdateSid`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowLoader(false)
if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
          setEditFormData({
            sid: "",
            txnAmt: "",
            mid: "",
            merchantName: "",
            id: "",
            vehicleMasterId: "",
          });
          handleCloseModal();
          FetchData(token);
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  const FetchData = async (token) => {
    setShowLoader(true);
    try {
      const apiResponse = await fetchAllSIDMasterSubmittedApplications(token);
      setShowLoader(false)
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
        console.log(apiResponse.data);
      } else {
        setApplications([]);
      }
      
    } catch (error) {
      // console.error(error);
      console.log(error);
      setShowLoader(false);
    }
  };
  
  const mappedData = applications
    ? applications?.map((item) => {
        const mappedItem = { ...item };
        mappedItem.maxTxnAmount = (
          <sapan>
            {" "}
            <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
          </sapan>
        );
        mappedItem.action = (
          <div className="d-flex gap-2 justify-content-evenly">
            <button
              className="btn btn-warning btn-sm"
              onClick={() =>
                openDetails(
                  mappedItem.mid,
                  mappedItem.company,
                  mappedItem.sid,
                  item.maxTxnAmount,
                  item.id,
                  item.vehicleMasterId,
                  item.masterMarchantid,
                  item.masterMarchantName,
                )
              }
            >
              Edit 
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={(e) => handelSearchLimit(e, item.sid, item.id)}
            >
              Remove
            </button>
          </div>
        );
        return mappedItem;
      })
    : [];

  useEffect(() => {
    FetchData(token);
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                  <MDBDataTable
                    className="dataTable"
                    striped
                    small
                    bordered
                    hover
                    responsive
                    data={{
                      columns: columns,
                      rows: mappedData,
                    }}
                    searching={true}
                    entries={50}
                    exportToCSV
                    theadColor="#333"
                    paginationLabel={["Previous", "Next"]}
                    sortable={true}
                    footer="none"
                  />
                </div>

                {/* EDIT FORM MODEL STARTS */}
                <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>Edit SID Master:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card className="shadow border-0 mb-4">
                      <Card.Body>
                        <div className="merchant-report-view">
                          <div className="report-table-view">
                            <div className="containerflag ">
                              <div className="row mt-4 ">
                                <div className="col-sm-12 col-md-8  offset-md-2 ">
                                  <label htmlFor="email">Merchant Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Merchant Name"
                                    value={editFormData.merchantName}
                                    readOnly
                                  />
                                </div>

                                <div className="col-sm-12 col-md-8 offset-md-2">
                                  <div className="form-group mt-3">
                                    <label htmlFor="email">Merchant MID</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="email"
                                      placeholder="Company Name"
                                      value={editFormData.mid}
                                      readOnly
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-8 offset-md-2">
                                  <div className="form-group mt-3">
                                    <label htmlFor="email">Enter SID</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="sid"
                                      name="sid"
                                      placeholder="Enter SID"
                                      value={editFormData.sid}
                                      onChange={handelDataChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-8 offset-md-2">
                                  <div className="form-group mt-3">
                                    <label htmlFor="email">
                                      Enter Maximum Transaction Amount
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="txnAmt"
                                      name="txnAmt"
                                      placeholder="Enter Maximum Txn Amount"
                                      value={editFormData.txnAmt}
                                      onChange={handelDataChange}
                                    />
                                    <span>
                                      <NumberInWords
                                        number={editFormData.txnAmt}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-8 offset-md-2">
                                  <div className="form-group mt-3">
                                    <label htmlFor="email">
                                      Select A Vehicle
                                    </label>
                                    <select
                                      id="companyDropdown"
                                      className="form-control"
                                      onChange={handelDataChange}
                                      name="vehicleMasterId"
                                      value={editFormData.vehicleMasterId}
                                    >
                                      <option value="">Select a Vehicle</option>
                                      {vehicleData?.map((vehicle) => (
                                        <option
                                          data-MasterName={
                                            vehicle.masterMerchantName
                                          }
                                          data-MasterId={
                                            vehicle.masterMerchantId
                                          }
                                          key={vehicle.id}
                                          value={vehicle.id}
                                        >
                                          {vehicle.vehicleName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-8 offset-md-2">
                                  <div className="form-group mt-3">
                                    <label htmlFor="email">
                                      Select Merchant Master 
                                    </label>
                                    <input
                                      id="companyDropdown"
                                      className="form-control"
                                      
                                      name="masterMerchantId"
                                      value={editFormData.masterMerchantName}
                                    />
                               </div>
                                </div>

                                <div className="d-flex justify-content-center mt-4">
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleUpdateSubmit}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                </Modal>
                <Modal show={isModal2Open} onHide={handleCloseModal2} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>SID Details:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card className="shadow border-0 mb-4">
                      <Card.Body>
                        <div className="text-danger mb-2   fw-bold">
                          Following merchant is mapped with this SID
                        </div>
                        <div className="merchant-report-view">
                          <div className="report-table-view">
                            <div className="containerflag ">
                              <div className="row mt-4 ">
                                <MDBDataTable
                                  className="dataTable"
                                  striped
                                  small
                                  bordered
                                  hover
                                  responsive
                                  data={{
                                    columns: sidDetailsColumn,
                                    rows: sidDetailsData,
                                  }}
                                  searching={true}
                                  entries={50}
                                  exportToCSV
                                  theadColor="#333"
                                  paginationLabel={["Previous", "Next"]}
                                  sortable={true}
                                  footer="none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-danger mb-2   fw-bold">
                          Do you want to disable this SID ?
                        </div>
                        <div className="d-flex gap-3">
                          <Button
                            className="border border-none bg-danger mm-3"
                            onClick={disableSID}
                          >
                            Disable
                          </Button>
                          <Button onClick={handleCloseModal2}>Cancel</Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default SubmittedMasterSIDApplications;