import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Button, Col } from "react-bootstrap";
import { getAllDailyTransactionDataPayout } from "../utils/ApiServices";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForPayout } from "../utils/DownloadHeaders";
import MidFiter from "./MidFiter";
import LivePayoutMultipleFilter from "./LivePayoutMultipleFilter";
import LivePayoutAdvanceFilter from "./LivePayoutAdvanceFilter";

function LivePayoutReport({ reportType, setShowLoader, dropDownData }) {
  const [data, setData] = useState();
  const [data2, setData2] = useState([]);
  const [show2, setShow2] = useState(false);
  const token = localStorage.getItem("token");
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [showCallBack, setShowCallBack] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const entriesPerPageOptions = [5, 10, 20, 30, 40, 50];
  const { mid } = useParams();
  const navigate = useNavigate();

  const getDailyTransactionData = (token, page = 0, size = entriesPerPage) => {
    setShowLoader(true);
    const response = getAllDailyTransactionDataPayout(token, page, size)
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setShowLoader(false);
        } else {
          setData([]);
          console.error("Error fetching data:", response.data.message);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getDailyTransactionData(token, currentPage);
  }, [reset, currentPage, entriesPerPage]);

  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      // Reset to first page when changing entries per page
      setCurrentPage(0);
      getDailyTransactionData(token, 0, value);
    }
  };

  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData(token, newPage);
  };

  const handleMidFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(true);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const handleShowOrNot = (index) => {
    setShowCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Created Date", field: "createDate", width: "auto" },

    { label: "Txn Request", field: "txnRequestTime", width: "auto" },
    { label: "Txn Response", field: "txnResponseTime", width: "auto" },
    {
      label: "Callback Received",
      field: "callbackReceiveTime",
      width: "auto",
    },
    {
      label: "Callback Send To Merchant",
      field: "callbackSendTime",
      width: "auto",
    },
    {
      label: "Callback Acknowlegment Received",
      field: "merchantcallbackResponseTime",
      width: "auto",
    },
    {
      label: "Callback Json Received from bank",
      field: "callbackJsonReceived",
      width: "auto",
    },
    { label: "Callback Json", field: "callbackJSON", width: "auto" },
    {
      label: "Merchant Callback Received",
      field: "merchantCallbackRecived",
      width: "auto",
    },
    {
      label: "Merchant Callback json",
      field: "merchantcallbackResponse",
      width: "auto",
    },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Reference Id", field: "reference", width: "auto" },
    { label: "VPA / UPI", field: "upi", width: "auto" },
    { label: "Created Time", field: "createTime", width: "auto" },

    { label: "UTR Number", field: "rrn", width: "auto" },
    { label: "Transaction Id", field: "txnId", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    { label: "Payment By", field: "paymentBy", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    { label: "Payout Status", field: "payoutStatus", width: "auto" },
    { label: "Category", field: "category", width: "auto" },
  ];

  const mappedData = data
    ? data?.map((item, index) => {
        return {
          ...item,
          date: item?.createDate.split(" ")[0],
          time: item?.createDate.split(" ")[1],
          time: item?.createDate.split(" ")[1],
          txnRequestTime: item?.txnRequestTime?.split(" ")[1],
          txnResponseTime: item?.txnResponseTime?.split(" ")[1],
          callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1],
          callbackSendTime: item?.callbackSendTime?.split(" ")[1],
          merchantcallbackResponseTime:
            item?.merchantcallbackResponseTime?.split(" ")[1],
          merchantCallbackRecived:
            item?.merchantCallbackRecived === "1" ? "Received" : "Not Received",
          callbackJsonReceived: item?.callbackJson
            ? "Received"
            : "Not Received",
          callbackJSON: item?.callbackJson ? (
            <>
              <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
                {showCallBack[index] ? "Hide" : "Show"}
              </Button>
              <div>{showCallBack[index] ? item.callbackJson : null}</div>
            </>
          ) : null,
        };
      })
    : [];

  const mappedData2 = data2?.map((item, index) => {
    return {
      ...item,
      date: item?.createDate.split(" ")[0],
      time: item?.createDate.split(" ")[1],
      time: item?.createDate.split(" ")[1],
      txnRequestTime: item?.txnRequestTime?.split(" ")[1],
      txnResponseTime: item?.txnResponseTime?.split(" ")[1],
      callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1],
      callbackSendTime: item?.callbackSendTime?.split(" ")[1],
      merchantcallbackResponseTime:
        item?.merchantcallbackResponseTime?.split(" ")[1],
      merchantCallbackRecived:
        item?.merchantCallbackRecived === "1" ? "Received" : "Not Received",
      callbackJsonReceived: item?.callbackJson ? "Received" : "Not Received",
      callbackJSON: item?.callbackJson ? (
        <>
          <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
            {showCallBack[index] ? "Hide" : "Show"}
          </Button>
          <div>{showCallBack[index] ? item.callbackJson : null}</div>
        </>
      ) : null,
    };
  });

  const calculateTotalAmountForSuccess = (data) => {
    // Filter the data to include only "success" transactions
    const successTransactions = data.filter(
      (item) => item.txnStatus.toUpperCase() === "SUCCESS"
    );
    return successTransactions.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    // Calculate the total sum of amounts for success transactions
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(data2);

  const HandleReset = () => {
    setReset(!reset);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };

  useEffect(() => {
    setData2([]);
  }, []);

  return (
    <>
      <section className="lincpay-dashboard-sec">
        <div className="d-flex align-items-center">
          <label htmlFor="entriesPerPage">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm"
            style={{ maxWidth: "100px" }}
          >
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <Col className="d-flex justify-content-end gap-2">
          <Button onClick={handleMidFilter}>Merchant</Button>
          <Button onClick={handleMultipleFilter}>More Filter</Button>
          <Button onClick={handleAdvanceFilter}>Advance Filter</Button>

          <Button
            className="d-flex align-items-center justify-content-center gap-2"
            onClick={HandleReset}
          >
            Reset<i class="bi bi-arrow-clockwise"></i>
          </Button>
        </Col>
        <div className="dasboard-view-count-boxes">
          <Card.Body>
            <div className="report-grid-view">
              <div className="material-table-records mb-3">
                {!mappedData2.length ? (
                  <MDBDataTable
                    className="dataTable"
                    striped
                    bordered
                    small
                    data={{
                      columns: columns,
                      rows: mappedData,
                    }}
                    searching={true}
                    responsive
                    sorting={true}
                    entries={10}
                    hover
                    page={currentPage}
                    onPageChange={handlePageChange}
                    pagination={false}
                    paging={false}
                  />
                ) : (
                  <MDBDataTable
                    className="dataTable overflow-x-scroll p-5"
                    hover
                    striped
                    small
                    bordered
                    responsive
                    sortable={true}
                    searching={true}
                    entries={20}
                    data={{
                      columns: columns,
                      rows: mappedData2,
                    }}
                    footer="none"
                    theadColor="#333"
                    paginationLabel={["Previous", "Next"]}
                  />
                )}
                {!mappedData2.length && data2.length === 0 && (
                  <div className="text-center mt-3">No data found</div>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <DownloadCsv
                  DownLoadData={!data2.length ? data : data2}
                  // Header={headerLabelsForPayout}
                  Name={"Payout"}
                />
                {!data2.length ? (
                  <div className="pagination-buttons d-flex justify-content-end gap-2">
                    <Button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 0}
                    >
                      Previous
                    </Button>
                    <Button onClick={handleNextPage}>Next</Button>
                  </div>
                ) : null}
                {totalAmountForSuccess != "0" ? (
                  <div className="d-flex align-items-center justify-content-end w-25 gap-4 border boeder-1 p-2 ">
                    <lable className="fs-4 w-50">Total Amount</lable>
                    <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
                      {totalAmountForSuccess}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </Card.Body>

          <MidFiter
            reportType={reportType}
            setShow2={setShow2}
            show2={show2}
            setLiveData={setData}
          />
          {isAdvanceFilterOpen ? (
            <LivePayoutAdvanceFilter
              setShowLoader={setShowLoader}
              setTransactionReportData={setData}
              setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
            />
          ) : isMultipleFilterOpen ? (
            <LivePayoutMultipleFilter
              setShowLoader={setShowLoader}
              setTransactionReportData={setData2}
              setIsMultipleFilterOpen={setIsMultipleFilterOpen}
              dropDownData={dropDownData}
            />
          ) : (
            ""
          )}
        </div>
        {/* </Container>  */}
      </section>
    </>
  );
}

export default LivePayoutReport;
