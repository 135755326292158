import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import {fetchSIDDetails} from "../utils/ApiServices";

function SIDDetails({ setShowLoader }) {
    const [applications, setApplications] = useState([]);
   const [token, setToken] = useState(localStorage.getItem("token"));
   
   const columns = [
      { label: "Merchant ID", field: "mid", width: "auto" },
      { label: "Merchant Name", field: "company", width: "auto" },
      { label: "SID", field: "sid", width: "auto" },
      { label: "Vehicle Master Name", field: "vehicleName", width: "auto" },
      {label: "Maximum Transaction Amoumt", field: "maxTxnAmount", width: "auto", },
      { label: "Create Date", field: "createDateTime", width: "auto" },
      // { label: "Removed Date", field: "updateDate", width: "auto" },
       ];
  
   const FetchData = async (token) => {
      setShowLoader(true);
      try {
        const apiResponse = await fetchSIDDetails(token);
        if (apiResponse.statusCode === 200) {
          setApplications(apiResponse?.data);
          console.log(apiResponse.data);

          
        } else {
          setApplications([])
        }
        setShowLoader(false);
      } catch (error) {
        
        console.log(error);
        setShowLoader(false);
      }
    };
   
    const mappedData = applications
      ? (applications?.map((item) => {
          const mappedItem = { ...item };
           mappedItem.updateDate= item.deleted?item.updatedDateTime:''
          return mappedItem;
        }))
      : [];
  
    useEffect(() => {
      FetchData(token);
    }, []);
  
    return (
      <>
        <div className="card p-4">
          <MDBDataTable
            className="dataTable"
            striped
            small
            bordered
            hover
            responsive
            data={{
              columns: columns,
              rows: mappedData,
            }}
            searching={true}
            entries={50}
            exportToCSV
            theadColor="#333"
            paginationLabel={["Previous", "Next"]}
            sortable={true}
            footer="none"
          />
        </div>
  
       
      </>
    );
  };

export default SIDDetails;