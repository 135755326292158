  
import { config, getCompleteDomain, getServerEnvironment } from "./helper";


const {Url}=config(getServerEnvironment(getCompleteDomain()))
 
  export const SWIPELINC_API =  Url
  
console.log(SWIPELINC_API , getServerEnvironment(getCompleteDomain()) )



export const LOGIN_API =
`${SWIPELINC_API}MerchantAdminPanel/admin/login`;

 export const FORGOT_PASSWORD_API =
`${SWIPELINC_API}MerchantAdminPanel/admin/passwordreset/forgotpassword`;

//  export const  GET_ALL_MERCHANT_PHASE_2_APPROVED =`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant-validation`;
 
 export const OTP_VERIFY_API =
`${SWIPELINC_API}MerchantAdminPanel/admin/passwordreset/otpverify/`

 export const CHANGE_PASSWORD =
`${SWIPELINC_API}MerchantAdminPanel/admin/passwordreset/changepassword`;

// REPORTS API:

 export const CHARGE_BACK_UPLOAD_API =
`${SWIPELINC_API}MerchantAdminPanel/chargeBack/upload`;

 export const GETALL_CHARGE_BACK_API =
 `${SWIPELINC_API}MerchantAdminPanel/chargeBack`

 export const GET_CHARGE_BACK_BY_CONDITION_API =
`${SWIPELINC_API}MerchantAdminPanel/chargeBack/ByCondition`;
 
 export const GETALL_SETTLEMENT_REPORT_API =
`${SWIPELINC_API}MerchantAdminPanel/admin/settlement`;

 export const SETTLEMENT_UPLOAD_API =
`${SWIPELINC_API}MerchantAdminPanel/settlement/upload`;

 export const GET_SETTLEMENT_BY_CONDITION_API =
`${SWIPELINC_API}MerchantAdminPanel/settlement/ByCondition`;

 export const PAYIN_REPORT_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllReport`;

 export const PAYIN_REPORT_BY_CONDITION_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByCondition`;

export const PAYOUT_REPORT_API =
`${SWIPELINC_API}MerchantAdminPanel/admin/payout/getAllUsage`;

export const WITHDRAW_REPORT_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllWithdrawal`;


// PHASE1 APIS:

 export const GETALL_SUBMITTED_MERCHANT_PHASE1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/Submitted`;
 
 export const GET_VIEW_APPROVE_PHASE1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/Approved`;
 
export const GET_VIEW_REJECTED_PHASE1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/rejected`;
 
export const GET_VIEW_PENDING_PHASE1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/Pending`;

 //  VIEW FILE APIS:
 export const VIEW_FILE_UPDATE_STATUS_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStatus`;

export const VIEW_FILE1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/`;

 export const GET_MERCHANT_BY_ID_PHASE1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/`

  export const FETCH_PAYMENT_SERVICES_PHASE1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getALLPaymentType`;
  
 export const SAVE_SELECTED_PAYMENT_SERVICES_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updatePaymentTypeService/`;


//   PHASE 2 APIS:

 export const STAGE2_APPLICATIONS_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Submitted`;

export const CHECK_FILE_IS_PDF = `${SWIPELINC_API}MerchantPanel/merchant/onboard/checkPdf`;

export const STAGE2_APPROVED_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Approved`;
 
 export const STAGE2_REJECTED_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/rejected`;
 
 export const  STAGE2_PENDING_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Pending`;

// MOA DETAILS API:
export const STAGE2_UPDATE_STATUS_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStage2/`;

export const STAGE2_GET_MERCHANT_BY_ID_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById`;

export const STAGE2_HANDLE_VIEW_DATA_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/viewData/`;

export const STAGE2_VIEW_PDF_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/`;

// VIEW MERCHANT APIS:
export const FETCH_MERCHANT_DETAILS_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;

export const FETCH_MERCHANT_OPEN_DETAILS_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/getmerchantByMid`;

export const FETCH_MERCHANT_TRANSACTION_REPORT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/getAllMerchantWithTransaction`;

export const FETCH_VIEW_MERCHANT_TRANSACTION_REPORT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreport`;

// TOOLS APIS:
// ALL PAYOUT REQUEST APIS:

 export const FETCH_ALL_PAYOUT_REPORT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout/`;

export const FETCH_APPROVED_PAYOUT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Approved`;

export const FETCH_REJECTED_PAYOUT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Rejected`;

export const FETCH_SUBMITTED_PAYOUT_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Submitted`;

export const FETCH_VIEW_DETAILS_PAYOUT_REPORT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout`;

export const FETCH_VIEW_DETAILS_UPDATE_PAYOUT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/reject`

export const FETCH_VIEW_DETAILS1_PAYOUT_REPORT_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout/`;



// ALL VENDOR REGISTRATION APIS:

export const FETCH_ALL_VENDORS_REGISTRATION_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors`;

export const FETCH_APPROVED_VENDOR_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors/Approved`;

export const FETCH_REJECTED_VENDOR_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors/Rejected`;

export const FETCH_SUBMITTED_VENDOR_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getAllVendors/Submitted`;

export const FETCH_VIEW_VENDOR_DETAILS_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getVendorById/`;

export const FETCH_VIEW_VENDOR_UPDATE_API=
`${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/updateStatus`;

export const FETCH_VIEW_VENDOR_DETAILS1_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/vendor/getVendorById`;

// MERCHANT MANAGEMENT APIS :

export const FETCH_FLAG_EDIT_FORM_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/updateMerchantsByMid`;
 
export const FETCH_FLAG_MANAGEMENT_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchantsFlags`;

export const FETCH_MANAGE_AMOUNT_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/hold/getholddata/`;

export const FETCH_SERVICE_CHARGE_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;

export const FETCH_SAVE_SERVICE_CHARGE_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/save/serviceCharge`;

export const FETCH_WITHDRAW_AMOUNT_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant`;

export const FETCH_WITHDRAW_AMOUNT_SAVE_API =
`${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/save`;

export const FETCH_WITHDRAWL_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllPandingWithdrawal`

export const FIND_BY_MID_AND_ORDERNO =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/findByMidAndOrderNo`;
 
export const UPLOAD_MERCHANT_DOCS =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/findByMidAndOrderNo`;

export const FETCH_ALL_ACCOUNT_DETAILS =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/getAll/AccountDetails`;

export const FETCH_ACCOUNT_DETAILS_BY_MID =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/getAll/AccountDetails/ByMid`;

export const FETCH_DOCUMENTS_BY_MID_FOR_ACCOUNT =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/viewData/`

export const FETCH_ALL_FRM_SUBMITTED_FORM =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/frm/getAllFRM`

export const FETCH_FRM_SUBMITTED_FORM_BY_MID =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/frm/getFRMByMid/`

export const FETCH_ALL_MERCHANT_VALIDATIONS =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant-validation`

export const FETCH_ALL_BANK_FORM_SUBMITTED =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/getall`
 
export const FETCH_BANK_FORM_SUBMITTED_BY_MID =
 `${SWIPELINC_API}/MerchantAdminPanel/merchant/mdr/getAllBymid/`

 export const UPLOAD_SWITCH_FILE =
 `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/switch/upload`

 export const UPLOAD_NODAL_FILE =
 `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/consolidated/upload`

export const UPLOAD_NODAL_TRUE_OR_FALSE =
 `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/isSwitchFile/upload`

export const GET_RECONCILIATION_DATA =
 `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getAll/reconciliationData`

 export const GET_UPLOADED_PDF_FILES =
 `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getUploaded/pdfs`

 export const GET_DOWNLOAD_EXCEL =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/CSV/Download/payin`

 export const PAYIN_FILTER_API =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByMultpalCondition`;

 export const PAYIN_FILTER_API_CSV_DOWNLOAD =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByMultpalConditionForCsvDownload`;

 export const GET_TXN_REPORT_ADVANCE_FILTER =
`${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByCondition`;

export const SETTLEMENT_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantAdminPanel/admin/settlement/getSettlementByMultipalCondition`;

export const CHARGEBACK_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantAdminPanel/chargeBack/ByMultpalCondition`;

export const PAYOUT_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/merchantPayoutRegister/byMultipleCondition`;

export const WITHDRAW_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/ByMultpalCondition`;

export const LIVE_WITHDRAWL_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}/MerchantAdminPanel/merchant/withdrawal/ByMultpalConditionWithoutMid`;

export const GURANTEE_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantAdminPanel/admin/guarantAmount/chargeBack/ByMultpalCondition`;

export const GET_ALL_MECHANT_PAYOUT_MASTERS =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMaster`

 export const SAVE_MERCHANT_PAYOUT_MASTER =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/saveMerchantPayoutMaster`

 export const  DISABLED_DATA_PAYOUT_MASTER =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMaster/DisableData`

 export const  GET_ALL_ROUTING_VIEW =
 `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/view-All`

 export const  GET_WALLET_BALANCE_BY_MID =
 `${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/get/walletBalance/`

 export const  FIND_BY_ORDER_NO=
 `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/findByOrderNo`
 export const  FIND_PAYIN_DETAILS=
 `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getPayinDetail/id`

 export const SID_DETAILS = 
 `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllEnableDisableSid`

 export const  GET_ALL_SUBMITTED_PAYOUT_ROUTING =
 `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/getAllMerchantPayoutMaster`

 export const  GET_ALL_DAILY_TRANSACTION_DATA_BOX =
 `${SWIPELINC_API}MerchantAdminPanel/MerchantAdminPanel/LiveReport/getAllMerchant`

 export const  GET_ALL_DAILY_TRANSACTION_DATA_TABLE =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllReportWithCurrentDate`

 export const  GET_ALL_DAILY_TRANSACTION_DATA_BOX_BY_MID =
 `${SWIPELINC_API}MerchantAdminPanel/MerchantAdminPanel/LiveReport/getDataBy`

 export const  GET_ALL_DAILY_TRANSACTION_DATA_TABLE_BY_MID =
 `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllReportWithCurrentDateWithMid`

 export const  GET_ALL_RECON_DATA_BY_MULTIPLE_CONDITION =
 `${SWIPELINC_API}/MerchantAdminPanel/reconciliationReport/getReconciliationByMultipleConditions`

 // UPDATE MERCHANT BANK DETAILS
export const UPDATE_MERCHANT_BANK_DETAILS = 
`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/savebankDetails`;

  // verify account number and ifsc code
  export const ACC_NUM_IFSC_VERIFY_API =
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/bankVerification`;

  /* get details by ifsc */
  export const IFSC_VERIFY_API =
  `${SWIPELINC_API}MerchantPanel/merchant/ekyc/findByIfsc`;

  // verify email is pre verified or not
export const VERIFY_MERCHANT_ONBOARD_EMAIL = `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/verified/email
`;

// verify mobile is pre verified or not
export const VERIFY_MERCHANT_ONBOARD_MOBILE = `${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`;

export const SAVE_ORGANISATIONAL_TYPE = `${SWIPELINC_API}MerchantAdminPanel/api/organisational-types/save`

export const   GET_ALL_ORGANISATIONAL_TYPE = `${SWIPELINC_API}/MerchantAdminPanel/api/organisational-types/getAll`;

export const   GET_ALL_ORGANISATIONAL_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/api/organisational-types/getAllWithActive`;

export const   GET_ALL_VEHICLE_COMMERCIAL_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/api/vehicle-commercial-types/getAllWithActive`;

export const   GET_ALL_VEHICLE_SETTLEMENT_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/admin/getAllVehicleSettlementType/isActiveTrue`;

export const   GET_ALL_VEHICLE_COMMERCIAL_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/admin/getAllVehicleCommercial/isActiveTrue`;

export const   GET_ALL_VEHICLE_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/merchant/paymentType/getALLPaymentTypeWithSubType`;

export const   GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE = `${SWIPELINC_API}/MerchantAdminPanel/admin/vehicleSubType/getAllActive`;

export const SUBMITTED_VEHICLE_SUBTYPE = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleSubType/getAll`;

export const SUBMITTED_VEHICLE_TYPE = `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getAllAtiveAndDisable`;

export const SUBMITTED_VEHICLE_COMMERCIAL = `${SWIPELINC_API}MerchantAdminPanel/admin/getAllVehicleCommercial`;

export const SAVE_VEHICAL_COMMERCIAL_TYPE = `${SWIPELINC_API}MerchantAdminPanel/api/vehicle-commercial-types/save`

export const SAVE_VEHICAL_SETTLEMENT_TYPE = `${SWIPELINC_API}MerchantAdminPanel/admin/saveVehicleSettlementType`

export const   GET_ALL_VEHICAL_COMMERCIAL_TYPE = `${SWIPELINC_API}MerchantAdminPanel/api/vehicle-commercial-types/getAll`;

export const   GET_ALL_VEHICAL_SETTLEMENT_TYPE = `${SWIPELINC_API}MerchantAdminPanel/admin/getAllVehicleSettlementType`;

export const   GET_ALL_VEHICAL_MASTER_FORMS = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getAllActive`;

export const   GET_ALL_VEHICAL_MASTER_FORMS_EKYC = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/findVehicleMasterByServiceType`;

export const   GET_ALL_ACTIVE_DISABLE_VEHICAL_MASTER = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getAll`;

export const   SAVE_VEHICLE_MASTER_FORM = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/save`;

export const   SAVE_VEHICLE_MASTER_UPDATE_FORM = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/update`;

export const   SAVE_ORGANISATIONAL_TYPE_UPDATE_FORM = `${SWIPELINC_API}/MerchantAdminPanel/api/organisational-types/update`;

// export const  GET_ALL_MERCHANT_PAYOUT_API_TEST =`${SWIPELINC_API}MerchantAdminPanel/merchant/getallmerchant-validation`;

export const  GET_LIVE_TXN_REPORT_ADVANCE_FILTER =`${SWIPELINC_API}/MerchantAdminPanel/merchant/reports/getreportByConditionWithoutMid`;

export const  LIVE_PAYIN_MORE_FILTER_API =`${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByMultpalConditionWithoutMID`;

export const  LIVE_PAYIN_MORE_FILTER_API_CSV_DOWNLOAD =`${SWIPELINC_API}/MerchantAdminPanel/merchant/reports/getreportByMultpalConditionWithoutMIDForCsvDownlload`;

export const  GET_ALL_TEMPORARY_REQUEST_DATA =`${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/period/getAllWithdrawalRequest`;

export const GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT =`${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllLiveTransactionsForOneDay`;

export const GET_ALL_DAILY_TRANSACTION_DATA_CHARGEBACK = `${SWIPELINC_API}MerchantAdminPanel/getAllLiveTransactionsForOneDayChargeBackData`;

export const GET_ALL_BY_ID_USING_GET = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getbyId/`;

export const GET_ALL_PHASE2_SUBMITTER = `${SWIPELINC_API}MerchantAdminPanel/merchant/vehicleMasterSubPaymentTypeService/getAllVehicleMasterSubPaymentTypeService`;


export const GET_ALL_DAILY_TRANSACTION_DATA_WITHDRAWL = `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/getAllLiveTransactionsForOneDayWithdrawalData`;

export const GET_ALL_DAILY_TRANSACTION_DATA_RECONCILIATION = `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getAllLiveTransactionsForOneDayReconciliationData`;

export const GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/payoutRagister/getAllLiveTransactionsForOneDay/`;

export const GET_ALL_DAILY_TRANSACTION_CHARGEBACK_ = `${SWIPELINC_API}MerchantAdminPanel/chargeBack/ByMultpalConditionWithoutMid`;

export const GET_ALL_DAILY_TRANSACTION_CHARGEBACK_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/getAllLiveTransactionsForOneDay/`;

export const GET_ALL_DAILY_TRANSACTION_WITHDRAWL_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/withdrawal/getAllLiveTransactionsForOneDay/`;

export const GET_ALL_DAILY_TRANSACTION_RECONCILIATION_BY_MID = `${SWIPELINC_API}/MerchantAdminPanel/reconciliationReport/getAllLiveTransactionsForOneDay/`;

export const CALLBACK_PAYOUT_BY_MID = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/findByMid/`

export const CHECK_CALLBACK_FOR_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/CheckPayoutCallBack`

export const CALLBACK_PAYIN_BY_MID =`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getByMid/`

export const CHECK_CALLBACK_PAYIN =`${SWIPELINC_API}MerchantAdminPanel/merchant/payin/checkPayinCallbackUrl`

export const   GET_ALL_MERCHANT_MASTER = `${SWIPELINC_API}MerchantAdminPanel/api/master-merchants`;

export const  LIVE_TRANSACTION_PAYOUT_WITHOUT_MID = `${SWIPELINC_API}/MerchantAdminPanel/merchant/payoutRagister/merchantPayoutRegister/byMultipleConditionWithoutMid`;

export const   GET_ALL_SCHEME = `${SWIPELINC_API}MerchantAdminPanel/api/schemes`;

export const   GET_ALL_BANK = `${SWIPELINC_API}MerchantAdminPanel/api/banks`;

export const   GET_ALL_SWITCH_DATA = `${SWIPELINC_API}MerchantAdminPanel/api/switches`;

export const   GET_ALL_AGGREGATOR_DATA = `${SWIPELINC_API}MerchantAdminPanel/api/aggregators`;

export const GET_TXN_REPORT = `${SWIPELINC_API}MerchantAdminPanel/merchant/getAllMerchantTransactionCalculation`

export const   DISABLE_VEHICLE_MASTER = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/disable`;

export const   ENABLE_VEHICLE_MASTER = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/enable`;

export const   VEHICLE_MASTER_PHASE_2_SAVE= `${SWIPELINC_API}MerchantAdminPanel/merchant/vehicleMasterSubPaymentTypeService/save/VehicleMasterSubPaymentTypeService`;

export const   VEHICLE_MASTER_PHASE_2_SUBMITTED= `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getAllSecondPhase`;

export const   VEHICLE_MASTER_PHASE_2_SUBMITTED_BY_ID= `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/getbyId/`;

export const SUBMITTED_PAYOUT =` ${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayoutByStatus/Submitted`;
 
export const FETCH_PAYOUT_DETAILS = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/getAllPayout`;

export const APPROVE_REJECT_PAYOUT = `${SWIPELINC_API}MerchantAdminPanel/merchant/payoutRagister/reject`;

export const SINGLE_PAYIN_REPORT =   `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreport`;

export const GET_PAYIN_BY_MULTIPLE_FILTER =`${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getReportBuMultipalConditionWithCountTxnStatusCode`

export const SAVE_WITHDRAWL_PERCENTAGE =`${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/period/update`

export const FETCH_ALL_MERCHANT_PAYIN_ROUTING_ONBORDING =`${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getMerchantPayin`

export const FETCH_ALL_MERCHANT_PAYOUT_ROUTING_ONBORDING =`${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getMerchantPayout`

export const FETCH_ALL_MERCHANT_EKYC_ROUTING_ONBORDING =`${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/getallmerchant-Ekyc`

export const FETCH_ALL_SUBMITTED_EKYC_ROUTING =`${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/getAllRouting`

export const FETCH_ALL_MERCHANT_EKYC_ROUTING_TOOLS =`${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/getallmerchant-Ekyc/tools`

export const  AGGREGATOR_SAVE =`${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/save`

export const  GET_ALL_AGGREGATOR =`${SWIPELINC_API}MerchantAdminPanel/api/master-merchants/`

export const  GET_ALL_PRIVILAGES =`${SWIPELINC_API}MerchantAdminPanel/admin/privileges/getAllPrivileges/subType`
 
export const  GET_ALL_PRIVILAGES_BY_ID =`${SWIPELINC_API}MerchantAdminPanel/admin/privileges/getByMasterMerchntId/`
 
 
export const  FETCH_PAYIN_FOR_ACKNOWLEDGMENT =`${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllCallBackAcknowledgement`
 
 
export const   AGGREGATOR_UPDATE=`${SWIPELINC_API}/MerchantAdminPanel/api/master-merchants/update`
 
 
export const  EnableDisableAggragator=`${SWIPELINC_API}/MerchantAdminPanel/api/master-merchants/`

export const  GET_ALL_USER=`${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/getByMasterMerchntId/`;

export const  GET_ALL_SUB_USER=`${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/getAll`


export const  AGGREGATOR_USERS_SAVE=`${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/save`
 
export const  GET_USER_MANAGEMENT_DATA_BY_ID =`${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/`

export const   USER_UPDATE=`${SWIPELINC_API}MerchantAdminPanel/api/aggregators/subUser/update`;

export const FRM_BLOCK_USER = `${SWIPELINC_API}MerchantAdminPanel/merchant/frm-block/save`;

export const FETCH_FRM_USERS = `${SWIPELINC_API}MerchantAdminPanel/merchant/frm-block/get`;

export const FETCH_MERCHANT_FOR_MASTERS_FORMS = `${SWIPELINC_API}MerchantAdminPanel/admin/vehicleMaster/findVehicleMasterByServiceType`;

export const FETCH_MERCHANT_FOR_SID =  `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getMasterMaschantDetailDemo`;

export const VEHICLE_MASTER_PHASE_2_UPDATE = `${SWIPELINC_API}MerchantAdminPanel/merchant/vehicleMasterSubPaymentTypeService/update/updateVehicleSubPaymentTypeService`

export const  GET_ALL_SID = `${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`

export const  GET_ALL_DAILY_TRANSACTION_DATA_BOX_WITH_FILTER=`${SWIPELINC_API}MerchantAdminPanel/MerchantAdminPanel/LiveReport/getAllMerchantByCondition`