import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { SWIPELINC_API } from "../../../utils/constant";
import axios from "axios";
import {
  MDRPercentageFetchByMid,
  MDRTypeFetch,
  getAllMerchantPhase2Approved,
} from "../../../utils/ApiServices";
import {
  stopCharacterInput,
  stopSpecialCharacterInput,
} from "../../../utils/helper";
import swal from "sweetalert";
import ServiceCharge from "../../../Merchant/MerchantManagement/ServiceCharge";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import InactiveServices from "../../InactiveServices";

const MDRPercentageEdit = ({ setShowLoader }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [mid, setMid] = useState("");
  const [fullName, setFullName] = useState("");
  const [MDRTypes, setMDRType] = useState();
  const [MDRData, setMDRData] = useState([]);
  const [activePhase, setActivePhase] = useState("Phase2");

  const hasPrivilage=useCheckPrivilages(176088)

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  const getMerchantData = async (token) => {
    try {
      setShowLoader(true);
      const response = await getAllMerchantPhase2Approved(token);
      setShowLoader(false);
      if (response?.statusCode === 200) {
        setMerchantData(response?.data);
        console.log(response?.data);
      } else {
        setMerchantData([]);
      }
    } catch (error) {
      setShowLoader(false);

      setMerchantData([]);
    }
  };

  useEffect(() => {
    getMerchantData(token);
  }, []);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => `${merchant.prifix} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
    } else {
      setMid("");

      setMDRData([]);
      setMDRType([]);
    }
  };

  const fetchtheTypeOfoption = async () => {
    setMDRData([]);
    setMDRType([]);
    // if (!mid === "") {
    const MDRType = await MDRPercentageFetchByMid(mid, token);

    if (MDRType?.statusCode === 200) {
      setMDRType(MDRType?.data ? MDRType?.data : []);
    } else if (MDRType?.statusCode === 304) {
      swal({
        title: "Failed",
        text: "No details found",
        icon: "error",
      });
      setMDRType([]);
      setMid("");
      setSelectedCompany("");
      setFullName("");
    }
    // }
  };

  const setMDrDataInit = (MDRTypes) => {
    MDRTypes.forEach((element) => {
      setMDRData({
        ...MDRData,
        [element.paymentTypeId]: element.mdrPercentage,
      });
    });
    setMDRData();
  };

  useEffect(() => {
    fetchtheTypeOfoption(mid.token);
  }, [mid]);

  const handleMDRChange = (value, mdrId, name) => {
    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }

    let data = MDRTypes.filter((elem) => {
      return elem.paymentTypeId === mdrId;
    });

    data[0].mdrPercentage = value;
    setMDRType([...MDRTypes]);
  };

  const handleMDRsubmit = async () => {
    const finalPayload = MDRTypes.map((type) => {
      return { ...type, mid: mid };
    });

    // let payload =  MDRTypes

    console.log("Payload-------------------------", finalPayload);
    // handleFormSubmit()
    setShowLoader(true);
    try {
      const response = await axios.put(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/mdr/updateMdrPercentage`,
        finalPayload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      console.log(response);
      // return response?.data;

      if (response.data.statusCode === 200) {
        setMDRType([]);
        setMid("");
        setSelectedCompany("");

        swal({
          title: "Success",
          text: "Merchant discount rate updated successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Alert!",
          text: response.data.message,
          icon: "error",
        });
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  return (
    <>
    {/* {hasPrivilage ? ( */}
      <> 
      <div className="subtab-sec-admin">
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <div className="merchant-tab-btn">
                  <ul className="report-link nav-report-btn">
                    {/* <li
                      className={activePhase === "Phase2" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase2")}
                    >
                      MDR
                    </li> */}

                    {/* <li
                      className={activePhase === "Phase1" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase1")}
                    >
                      Payin
                    </li> */}
                  </ul>
                </div>

                <Card.Body>
                  {activePhase === "Phase2" ? (
                    <div>
                      <div className="row mt-4">
                        <div className="col-sm-12 col-md-6 offset-md-3">
                          <lable>Select Merchant *</lable>
                          <select
                            id="companyDropdown"
                            className="form-control"
                            onChange={handleCompanyChange}
                            value={selectedCompany}
                          >
                            <option value="">Select a Company</option>
                            {merchantData.map((merchant) => (
                              <option
                                key={merchant.mid}
                                value={`${merchant.prifix} :- ${merchant.merchantName}`}
                              >
                                {merchant.prifix} :- {merchant.merchantName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-sm-12 col-md-6 offset-md-3">
                          <div className="form-group">
                            <label htmlFor="email">Merchant ID</label>
                            <input
                              required
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Merchant ID"
                              value={mid}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ServiceCharge
                      setShowLoader={setShowLoader}
                      data={merchantData}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {activePhase === "Phase2" ? (
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <Card.Body>
                  <Row className="p-3">
                    <label className="form-label fw-bold mb-2">
                      MERCHANT DISCOUNT RATE
                      <sup className="text-danger">*</sup>
                    </label>
                    <label
                      className="fw-semi-bold text-danger mb-5"
                      style={{ fontSize: "13px" }}
                    >
                      (Please enter the MDR rate in percentage.)
                    </label>

                    {MDRTypes?.map((service) => (
                      <Col xs={12} lg={3} md={5} key={service.paymentTypeId}>
                        <div className="form-group mt-3">
                          <label className="form-label">
                            {service.paymentService}
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            type="number"
                            name={service.paymentService}
                            className="form-control"
                            value={service.mdrPercentage}
                            onChange={(e) =>
                              handleMDRChange(
                                e.target.value,
                                service.paymentTypeId,
                                service.paymentService
                              )
                            }
                            required
                          />
                        </div>
                      </Col>
                    ))}

                    {MDRTypes?.length === 0 ? (
                      ""
                    ) : (
                      <Col xs={12} lg={12} md={12}>
                        <div className="d-flex justify-content-end mt-3">
                          <div className="form-group tool-bar-btn mb-0 mt-3">
                            <button
                              className="submit-button submit_link_info btn btn-success"
                              onClick={handleMDRsubmit}
                              id="mdrSave"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}
      </>
      {/* ) : (<InactiveServices location={"privilages"}/>)} */}
       
    </>
  );
};

export default MDRPercentageEdit;
