import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import useCheckPrivilages from "../../utils/checkPrivilages";
import InactiveServices from "../../components/InactiveServices";

function Release({ setShowLoader, data }) {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({
    mid: "",
  });

  const hasPrivilage = useCheckPrivilages(2694);

  const [changedAmount, setChangedAmount] = useState("");

  const [selectedReleaseOption, setSelectedReleaseOption] = useState("");
  const [releaseAmount, setReleaseAmount] = useState("");
  const [userEnteredAmount, setUserEnteredAmount] = useState("");

  useEffect(() => {
    setMerchantData(data);
  }, []);

  const fetchReleaseAmount = async () => {
    try {
      setShowLoader(true);

      const payload = {
        amountFor: selectedReleaseOption === "hold" ? "hold" : "deposite",
        mid: formData.mid,
      };

      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/release/getdepositeAndHoldAmount`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        const responseData = response.data.data[0];
        const releaseAmount =
          responseData.totalHold || responseData.allSecurityAmount;
        setReleaseAmount(releaseAmount);
      } else {
        console.error("Error fetching release amount:", response.data.message);
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching release amount:", error);
    }
  };

  useEffect(() => {
    if (selectedReleaseOption) {
      fetchReleaseAmount();
    }
  }, [selectedReleaseOption]);

  const handleSaveUserEnteredAmount = async () => {
    if (!selectedReleaseOption) {
      swal({
        title: "Error",
        text: "Please select a release option before saving.",
        icon: "error",
      });
      setReleaseAmount("");
      setUserEnteredAmount("");
      setShowLoader(false);

      return;
    }

    try {
      const payload = {
        amountFor: selectedReleaseOption === "hold" ? "hold" : "deposite",
        mid: formData.mid,
      };

      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/release/getdepositeAndHoldAmount`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        const responseData = response.data.data[0];
        const availableAmount =
          selectedReleaseOption === "hold"
            ? responseData.totalHold
            : responseData.allSecurityAmount;

        if (parseFloat(userEnteredAmount) > parseFloat(availableAmount)) {
          const alertTitle =
            selectedReleaseOption === "hold" ? "Hold Alert" : "Deposit Alert";
          swal({
            title: alertTitle,
            text: `Release amount must be Lesser than ${
              selectedReleaseOption === "hold" ? "hold" : "deposit"
            } amount.`,
            icon: "error",
          });
          setReleaseAmount("");
          setUserEnteredAmount("");
          return;
        }

        const savePayload = {
          amount: userEnteredAmount,
          mid: formData.mid,
          releaseFrom: selectedReleaseOption === "hold" ? "hold" : "deposite",
        };

        const saveResponse = await axios.post(
          `${SWIPELINC_API}MerchantAdminPanel/merchant/release/save`,
          savePayload,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        );

        if (saveResponse.data.statusCode === 200) {
          swal({
            title: "Success",
            text: "Amount saved successfully.",
            icon: "success",
          });
          setUserEnteredAmount("");
          setReleaseAmount("");
          setSelectedCompany("");
          setSelectedReleaseOption("");
          setFormData({
            email: "",
            mid: "",
            mobile: "",
            companyName: "",
          });
        } else {
          console.error("Error saving the amount:", saveResponse);
        }
        setShowLoader(false);
      } else {
        console.error("Error fetching release amount:", response);
      }
    } catch (error) {
      console.error("Error saving the amount:", error);
    }
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;

    if (selectedCompany === "") {
      setFormData({
        mid: "",
      });

      setSelectedCompany("");
      setSelectedReleaseOption("");
      setReleaseAmount("");
      setUserEnteredAmount("");

      return;
    }
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => `${merchant.prifix} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
      });

      setChangedAmount("");
      setReleaseAmount("");
      setSelectedReleaseOption("");
      setUserEnteredAmount("");
    }
  };
  const handleReleaseOptionChange = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === "") {
      setSelectedReleaseOption("");
      setReleaseAmount("");
      return;
    } else {
      setSelectedReleaseOption(selectedOption);
    }
  };
  const handleUserEnteredAmountChange = (event) => {
    const value = event.target.value;

    if (/^(?!0\d)(\d*(\.\d*)?)$/.test(value)) {
      setUserEnteredAmount(value);
    }
  };
  return (
    <>
      {/* {hasPrivilage ? ( */}
        <div className="containerflag">
          {/* <h1 className="mt-3">Select</h1> */}
          <div className="row mt-4">
            <div className="col-sm-12 col-md-6 offset-md-3">
              <select
                id="companyDropdown"
                className="form-control"
                onChange={handleCompanyChange}
                value={selectedCompany}
              >
                <option value="">Select a Company</option>
                {merchantData.map((merchant) => (
                  <option key={merchant.mid} value={`${merchant.prifix} :- ${merchant.merchantName}`}>
                  {merchant.prifix} :- {merchant.merchantName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-12 col-md-6 offset-md-3">
              <div className="form-group">
                <label htmlFor="email">Merchant ID</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Merchant ID"
                  value={formData.mid}
                  readOnly
                />
              </div>

              <div className="form-group">
                <label htmlFor="depositOrHold">Release From</label>
                <select
                  className="form-control"
                  id="depositOrHold"
                  value={selectedReleaseOption}
                  onChange={handleReleaseOptionChange}
                >
                  <option value="">Select</option>
                  <option value="hold">Hold</option>
                  <option value="deposit">Deposit</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="releaseAmount"> Amount (in Rupees)</label>
                <div className="d-flex gap-4">
                  <input
                    type="text"
                    className="form-control"
                    id="releaseAmount"
                    placeholder="Release Amount"
                    value={releaseAmount}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="userEnteredAmount">
                  Enter Amount (in Rupees)
                </label>
                <div className="d-flex gap-4">
                  <input
                    type="text"
                    className="form-control"
                    id="userEnteredAmount"
                    placeholder="Enter Amount"
                    value={userEnteredAmount}
                    onChange={handleUserEnteredAmountChange}
                  />
                  <button
                    className="toolbutton"
                    onClick={handleSaveUserEnteredAmount}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* ) : (
        <InactiveServices location={"privilages"} />
      )} */}
    </>
  );
}

export default Release;
