import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import { saveWithdrawlPercentage } from "../../utils/ApiServices";

function FlagEditForm({ mid, closeModal, showModal, data, onCloseModal }) {
  const [formData, setFormData] = useState({});
  const [form, setForm] = useState({
    IMPS: 0,
    UPI: 0,
    VIR: 0,
  });
  console.log(data)
  const [token] = useState(localStorage.getItem("token"));
  const [isWithdrawalAuto, setIsWithdrawalAuto] = useState(false);
  const [withdrawalPeriod, setWithdrawalPeriod] = useState("");

  useEffect(() => {
    const merchantData = data.find((item) => item.mid === mid);
    if (merchantData) {
      setFormData({
        isActive: merchantData.isActive === true ? "Enabled" : "Disabled",
        isAmountHold: merchantData.isAmountHold === true ? "Auto" : "Manual",
        isPayoutAutoApprovel:
          merchantData.isPayoutAutoApprovel === true ? "Auto" : "Manual",
        isProfileEdit:
          merchantData.isProfileEdit === true ? "Enabled" : "Disabled",
        isTransactionAllow:
          merchantData.isTransactionAllow === true ? "Enabled" : "Disabled",
        isVendorAutoApproved:
          merchantData.isVendorAutoApproved === true ? "Auto" : "Manual",
        isWithdrawlAuto:
          merchantData.isWithdrawlAuto === true ? "Auto" : "Manual",
        mid,
      });
      console.log(merchantData.accountPercentage)
      if (merchantData.isWithdrawlAuto === true) {
        setIsWithdrawalAuto(true);
        setForm({
          "VIR":merchantData.accountPercentage  || 0 ,
          "IMPS":merchantData.payoutPercentageImps || 0 ,
          "UPI":merchantData.payoutPercentageUpi || 0,
        })
        setWithdrawalPeriod(merchantData.withdrawalPeriod);
      }
      console.log(form)
    }
  }, [data, mid]);

  const flagData = [
    {
      name: "Login",
      key: "isActive",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    { name: "Hold", key: "isAmountHold", enabled: "Auto", disabled: "Manual" },
    {
      name: "Profile Edit",
      key: "isProfileEdit",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Transaction",
      key: "isTransactionAllow",
      enabled: "Enabled",
      disabled: "Disabled",
    },
    {
      name: "Vendor",
      key: "isVendorAutoApproved",
      enabled: "Auto",
      disabled: "Manual",
    },
    {
      name: "Payout",
      key: "isPayoutAutoApprovel",
      enabled: "Auto",
      disabled: "Manual",
    },
    {
      name: "Withdraw",
      key: "isWithdrawlAuto",
      enabled: "Auto",
      disabled: "Manual",
    },
  ];

  const handlePercentageSave = async () => {
    console.log("percentageRunnnnnnnnnnnn-----------");
    const payload = {
      accountPercentage: form.VIR,
      mid: formData.mid,
      payoutPercentageImps: form.IMPS,
      payoutPercentageUpi: form.UPI,
      period: withdrawalPeriod,
    };
    try {
      const res = await saveWithdrawlPercentage(payload, token);
      console.log(res);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "Data saved successfully",
          icon: "success",
        });
        setForm({
          IMPS: 0,
          UPI: 0,
          VIR: 0,
        });
        setFormData({});
      } else {
        swal({
          title: "Failed",
          text: "Failed To Save Data.",
          icon: "error",
        });
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: "Something Went Wronggggggggggggggggggggg.",
        icon: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(form.VIR + form.IMPS + form.UPI);

    if (form.VIR + form.IMPS + form.UPI > 100) {
      swal({
        title: "error",
        text: "Cannot Save More Than 100%.",
        icon: "error",
      });
      return;
    }

    if( isWithdrawalAuto){
      if (form.VIR === 0 && form.IMPS === 0 && form.UPI === 0) {
        swal({
          title: "error",
          text: "Please Fill Atleast One Percentage field.",
          icon: "error",
        });
        return;
      }
    }


    try {
      const updatedData = { ...formData };

      updatedData.isActive = updatedData.isActive === "Enabled";
      updatedData.isAmountHold = updatedData.isAmountHold === "Auto";
      updatedData.isPayoutAutoApprovel =
        updatedData.isPayoutAutoApprovel === "Auto";
      updatedData.isProfileEdit = updatedData.isProfileEdit === "Enabled";
      updatedData.isTransactionAllow =
        updatedData.isTransactionAllow === "Enabled";
      updatedData.isVendorAutoApproved =
        updatedData.isVendorAutoApproved === "Auto";
      updatedData.isWithdrawlAuto = updatedData.isWithdrawlAuto === "Auto";
      updatedData.withdrawalPeriod = isWithdrawalAuto ? withdrawalPeriod : "";
      updatedData.payoutPercentageImps = isWithdrawalAuto ? form.IMPS : "";
      updatedData.payoutPercentageUpi = isWithdrawalAuto ? form.UPI : "";
      updatedData.accountPercentage = isWithdrawalAuto ? form.VIR : "";

      const response = await axios.put(
       `${SWIPELINC_API}MerchantAdminPanel/merchant/updateMerchantsByMid`,
        updatedData,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );

      if (response.data.statusCode === 200) {
        swal({
          title: "success",
          text: "Flag Data Updated Successfully.",
          icon: "success",
        });
        setForm({
          'IMPS':0,
          'UPI':0,
          'VIR':0,
        })
        setFormData({});
        onCloseModal();
      } else if (response.data.statusCode === 304) {
        swal({
          title: "error",
          text: "No Data Found",
          icon: "error",
        });
        onCloseModal();
      } else {
        swal({
          title: "error",
          text: "Failed To Update Flag Data.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error updating flags:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "isWithdrawlAuto" && value === "Auto") {
      setIsWithdrawalAuto(true);
    }
    if (name === "isWithdrawlAuto" && value === "Manual") {
      setIsWithdrawalAuto(false);
    }
  };

  const handlePercentageSubmit = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (!value) {
      setForm({ ...form, [name]: 0 });
      return;
    }
  
    if (value <= 100) {
      setForm({ ...form, [name]: parseInt(value) });
    } else {
      setForm({ ...form, [name]: 100 }); 
    }
  };

  return (
    <div className={`flag-edit-form ${showModal ? "show" : ""}`}>
      <form onSubmit={handleSubmit} style={{ width: "80%", margin: "0 auto" }}>
        <div className="form-row  border border-1">
          {flagData.map((flag) => (
            <div className=" border border-1 align-content-center p-3">
              <div
                className="form-group w-100 d-flex justify-content-between   "
                key={flag.key}
              >
                <label className="w-50 " htmlFor={flag.key}>
                  {flag.name}
                </label>
                <div className="d-flex w-75 justify-content-between ">
                  <div className="form-check w-50 form-check-inline d-flex  flex-start gap-4">
                    <input
                      type="radio"
                      name={flag.key}
                      value={flag.enabled}
                      checked={formData[flag.key] === flag.enabled}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={flag.key + "Enabled"}
                    >
                      {flag.enabled}
                    </label>
                  </div>

                  <div className="form-check w-50 form-check-inline d-flex flex-start gap-4">
                    <input
                      type="radio"
                      name={flag.key}
                      value={flag.disabled}
                      checked={formData[flag.key] === flag.disabled}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={flag.key + "Disabled"}
                    >
                      {flag.disabled}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {isWithdrawalAuto ? (
            <div className="mt-2 d-flex pb-3">
              <div className="col-5 d-flex align-items-center px-3 ">
                <label htmlFor="email">Update Withdrawal Period</label>
              </div>
              <div className="col-7">
                <select
                  id=""
                  className="form-control mt-1"
                  onChange={(e) => setWithdrawalPeriod(e.target.value)}
                  value={withdrawalPeriod}
                  name="withdrawalPeriod"
                >
                  <option value="">Select Withdrawal Period</option>
                  <option value="T+1">T+1</option>
                  <option value="T+3">T+3</option>
                  <option value="T+7">T+7</option>
                  <option value="T+15">T+15</option>
                  <option value="3 HRS">3 Hours</option>
                  <option value="6 HRS">6 HoursS</option>
                  <option value="12 HRS">12 Hours</option>
                  <option value="24 HRS">24 Hours</option>
                </select>
              </div>
            </div>
          ) : (
            ""
          )}

          {isWithdrawalAuto ? (
            <>
              <div className="mt-2 d-flex pb-3">
                <div className="col-5 d-flex align-items-center px-3 ">
                  <label htmlFor="email">Payout (IMPS)</label>
                </div>
                <div className="col-7">
                  <input
                  type="text"
                  // pattern="^(100|[1-9]?[0-9])$"
                    id=""
                    name="IMPS"
                    className="form-control mt-1"
                    onChange={handlePercentageSubmit}
                    value={form.IMPS}
                  />
                </div>
              </div>
              <div className="mt-2 d-flex pb-3">
                <div className="col-5 d-flex align-items-center px-3 ">
                  <label htmlFor="email">Payout (UPI)</label>
                </div>
                <div className="col-7">
                  <input
                  type="text"
                  // pattern="^(100|[1-9]?[0-9])$"
                    id=""
                    name="UPI"
                    className="form-control mt-1"
                    onChange={handlePercentageSubmit}
                    value={form.UPI}
                  />
                </div>
              </div>
              <div className="mt-2 d-flex pb-3">
                <div className="col-5 d-flex align-items-center px-3 ">
                  <label htmlFor="email">Wallet</label>
                </div>
                <div className="col-7">
                  <input
                  type="text"
                  // pattern="^(100|[1-9]?[0-9])$"
                    id=""
                    name="VIR"
                    className="form-control mt-1"
                    onChange={handlePercentageSubmit}
                    value={form.VIR}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="button-container mt-3 d-flex gap-3">
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
}

export default FlagEditForm;