import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { SWIPELINC_API } from "../utils/constant";
import { Link } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForAllMerchant } from "../utils/DownloadHeaders";

function MerchantTransaction({ setShowLoader }) {
  const [xyzReportData, setxyzReportData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    fetchMerchantTransaction();
  }, []);

  const fetchMerchantTransaction = async () => {
    setShowLoader(true);
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/getAllMerchantWithTransaction`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setxyzReportData(response?.data?.data);
        console.log(xyzReportData);
      } else {
        console.error("Api response error:", response.data);
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching transaction report:", error);
      setShowLoader(false);
    }
  };
  const columns = [
    { label: "Company Name", field: "name", width: "auto" },
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "PayIn Amount", field: "totalTransactionAmount", width: "auto" },
    { label: "Settlement Amount", field: "settlmentAmount", width: "auto" },
    { label: "ChargeBack Amount", field: "chargeBackAmount", width: "auto" },
    { label: "Hold Amount", field: "holdAmount", width: "auto" },
    { label: " Deposit", field: "securityDeposit", width: "auto" },
    { label: "Payout Amount", field: "payoutAmount", width: "auto" },
    { label: "Withdrawl Amount", field: "withdrawalAmount", width: "auto" },
    { label: "Guarantee Amount", field: "guaranteeAmount", width: "auto" },
    { label: "Available Credit Amount", field: "creditBalance", width: "auto" },
    {label: "Settlement Adjustment amount",field: "sumOfSettlemenetAdjustAmount",width: "auto", },
    { label: "Service Charge", field: "serviceCharge", width: "auto" },
    { label: "Balance (Pay In)", field: "remaningAmount", width: "auto" },
    {label: "Balance (Payout UPI)",field: "ballancePayoutUpi",width: "auto" },
    {label: "Balance (Payout IMPS)",field: "ballancePayoutImps",width: "auto",},
    { label: "Vendors", field: "totalVendors", width: "auto" },
    { label: "Release Amount", field: "releaseAmount", width: "auto" },
    { label: "Wallet Balance", field: "walletBalance", width: "auto" },
  ];

  const mappedData = xyzReportData?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      if (column.field === "totalTransactionAmount") {
        mappedItem[column.field] = (
          <Link to={`/TransactionReport/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "chargeBackAmount") {
        mappedItem[column.field] = (
          <Link to={`/chargeBackAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "settlmentAmount") {
        mappedItem[column.field] = (
          <Link to={`/settlmentAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "withdrawalAmount") {
        mappedItem[column.field] = (
          <Link to={`/withdrawalAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "guaranteeAmount") {
        mappedItem[column.field] = (
          <Link to={`/guaranteeAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "payoutAmount") {
        mappedItem[column.field] = (
          <Link to={`/payoutAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "creditBalance") {
        mappedItem[column.field] = (
          <Link to={`/availableCreditAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "sumOfSettlemenetAdjustAmount") {
        mappedItem[column.field] = (
          <Link to={`/SettlementAdjustmentAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "totalVendors") {
        mappedItem[column.field] = (
          <Link to={`/merchantVendors/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "releaseAmount") {
        mappedItem[column.field] = (
          <Link to={`/releaseAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? item[column.field]
              : "0"}
          </Link>
        );
      } else if (column.field === "walletBalance"){
        mappedItem[column.field] =(
          <Link to = {`/walletBalance/${item.mid}`}>
            {(item[column.field]!== null) & (item[column.field] !== "")
            ? item[column.field]:
            "0"
            }
          </Link>
        )
      }
      else if (column.field === "serviceCharge") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? item[column.field]
            : "0";
      } else if (column.field === "holdAmount") {
        mappedItem[column.field] =(
        <Link to = {`/holdAmount/${item.mid}`}>
          {(item[column.field] !== null) & (item[column.field] !== "")
            ? item[column.field]
            : "0"}
        </Link>
    );
      }
       else if (column.field === "securityDeposit") {
        mappedItem[column.field] =(
          <Link to={`/securityDeposit/${item.mid}`}>
         { (item[column.field] !== null) & (item[column.field] !== "")
            ? item[column.field]
            : "0"}
            </Link>
        )
      } else if (column.field === "remaningAmount") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? item[column.field]
            : "0";
      } else if (column.field === "ballancePayoutUpi") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? item[column.field]
            : "0";
      } else if (column.field === "ballancePayoutImps") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? item[column.field]
            : "0";
      } else {
        mappedItem[column.field] = item[column.field];
      }
    });
    return mappedItem;
  });

  return (
    <>
      
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4">
              <Card.Body>
                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      small
                      bordered
                      hover
                      responsive
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      entries={50}
                      paginationLabel={["Previous", "Next"]}
                      sortable={true}
                      footer="none"
                    />
                  </div>
                  <DownloadCsv DownLoadData={xyzReportData} Header={headerLabelsForAllMerchant}  Name={"All-Merchant-transaction"}/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
   
    </>
  );
}

export default MerchantTransaction;