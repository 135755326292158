import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import {
  getAllDailyTransactionDataByMid,
  getAllDailyTransactionDataChargebackByMid,
  getAllDailyTransactionDataPayout,
  getAllDailyTransactionDataPayoutByMid,
  getAllDailyTransactionDataReconciliationByMid,
  getAllDailyTransactionDataTableByMid,
  getAllDailyTransactionDataWithdrawByMid,
  getAllMerchantPhase2Approved,
} from "../utils/ApiServices";

const MidFiter = ({
  reportType,
  setShow2,
  show2,
  setLiveData,
  setLiveBoxData,
}) => {
  const [merchantID, setMerchantID] = useState("");
  const token = localStorage.getItem("token");

  console.log(reportType)

  // const [token,setToken] = useState(localStorage.getItem ("token"));
  const [dropDownData, setDropDownData] = useState([]);
  const [formData, setFormData] = useState({
    mid: "",
  });
  const [selectedCompany, setSelectedCompany] = useState("");

  const getMerchantData = async (token) => {
    try {
      const response = await getAllMerchantPhase2Approved(token);
      if (response?.statusCode === 200) {
        setDropDownData(response?.data);
        console.log(response?.data);
      } else {
        setDropDownData([]);
      }
    } catch (error) {
      setDropDownData([]);
    }
  };

  useEffect(() => {
    // setActivePhase("Phase1");
    getMerchantData(token);
  }, []);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    if (selectedCompany === "") {
      setFormData({
        mid: "",
      });

      setSelectedCompany("");
      return;
    }

    setSelectedCompany(selectedCompany);

    const selectedMerchant = dropDownData.find(
      (merchant) => `${merchant.prifix} :- ${merchant.merchantName}` === selectedCompany
    );
    console.log(selectedMerchant);

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
      });
    }
  };

  const getDailyTransactionDataByMId = (merchantID, token) => {
    const response = getAllDailyTransactionDataByMid(merchantID, token)
      .then((response) => {
        setFormData({
          mid: "",
        });
        setSelectedCompany("")
        if (response.statusCode === 200) {
          setLiveBoxData(response?.data);
          
        } else {
          setLiveBoxData([]);
          console.error("Error fetching data:", response.data.message);
        }
       
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const getDailyTransactionDataTableByMId = (merchantID, token) => {
    const response = getAllDailyTransactionDataTableByMid(merchantID, token)
      .then((response) => {
        setFormData({
          mid: "",
        });
        setSelectedCompany("")

        if (response.statusCode === 200) {
          setLiveData(response?.data);
         
        } else {
          setLiveData([]);
          console.error("Error fetching data:", response.data.message);
        }
      
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const getDailyTransactionDataPayout = (mid, token) => {
    const response = getAllDailyTransactionDataPayoutByMid(mid, token)
      .then((response) => {
        setFormData({
          mid: "",
        });
        setSelectedCompany("")
        if (response.statusCode === 200) {
          setLiveData(response?.data);
         
        } else {
          setLiveData([]);
          console.error("Error fetching data:", response.data.message);
        }
       
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const getDailyTransactionDataWithdraw = (mid,token) => {
    const response = getAllDailyTransactionDataWithdrawByMid(mid,token)
      .then((response) => {
        setFormData({
          mid: "",
        });
        setSelectedCompany("")
        if (response.statusCode === 200) {
          setLiveData(response?.data);
          
        } else {
          setLiveData([]);
          console.error("Error fetching data:", response.data.message);
        }
        
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const getDailyTransactionDataReconciliation = (mid,token) => {
    const response = getAllDailyTransactionDataReconciliationByMid(mid,token)
      .then((response) => {
        setFormData({
          mid: "",
        });
        setSelectedCompany("")
        if (response.statusCode === 200) {
          setLiveData(response?.data);
          
        } else {
          setLiveData([]);
          console.error("Error fetching data:", response.data.message);
        }
         
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const getDailyTransactionDataChargeback = (mid, token) => {
    const response = getAllDailyTransactionDataChargebackByMid(mid, token)
      .then((response) => {
        setFormData({
          mid: "",
        });
        setSelectedCompany("")
        if (response.statusCode === 200) {
          setLiveData(response?.data);
          
        } else {
          setLiveData([]);
          console.error("Error fetching data:", response.data.message);
        }
         
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const HandleSearch = () => {
    if (reportType === "Phase1") {
      getDailyTransactionDataTableByMId(formData.mid, token);
      getDailyTransactionDataByMId(formData.mid, token);
    } else if (reportType === "Phase2") {
      getDailyTransactionDataPayout(formData.mid, token);
    } else if (reportType === "Phase3") {
      getDailyTransactionDataChargeback(formData.mid, token);
    } else if (reportType === "Phase4") {
      getDailyTransactionDataWithdraw(formData.mid, token);
    } else if (reportType === "Phase5") {
      getDailyTransactionDataReconciliation(formData.mid, token);
    }
    HandleClose();
  };

  const HandleClose = () => {
    setShow2(false);
  };

  return (
    <Modal show={show2} size="md" centered>
      <Modal.Header className="bg-dark ">
        <Modal.Title className="text-white">Select Merchant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" ">
          <div className=" ">
            <select
              id="companyDropdown"
              className="form-control"
              onChange={handleCompanyChange}
              value={selectedCompany}
            >
              <option value="">Select a Company</option>
              {dropDownData.map((merchant) => (
                <option key={merchant.mid} value={`${merchant.prifix} :- ${merchant.merchantName}`}>
                {merchant.prifix} :- {merchant.merchantName}
                </option>
              ))}
            </select>
          </div>
          <div className="  mt-2">
            <input
              id="companyDropdown"
              className="form-control"
              value={formData.mid}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          size="sm"
          className="text-capitlize"
          onClick={HandleClose}
        >
          Close
        </Button>
        <Button
          className="text-capitlize"
          size="sm"
          variant="primary"
          onClick={HandleSearch}
        >
          <i class="bi bi-search mx-1"></i> Search
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MidFiter;
