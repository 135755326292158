import React, { useState, useEffect } from "react";
import axios from "axios";
import PopupMessage from "./PopupMessage";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import { DateTime } from "luxon";
import useCheckPrivilages from "../../utils/checkPrivilages";
import InactiveServices from "../../components/InactiveServices";

const WithdrawAmount = ({ setShowLoader, data }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [utrNumber, setUtrNumber] = useState("");
  const [transactionDate, setTransactionDate] = useState("");

  const hasPrivilage=useCheckPrivilages(2706)

  const [securityAmountError, setSecurityAmountError] = useState("");
  const [mid, setMid] = useState("");

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("false");
  const [transferType, setTransferType] = useState("");

  useEffect(() => {
    setMerchantData(data);
  }, [data]);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData?.find(
      (merchant) => `${merchant.prifix} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
    }
  };

  const handleWithdrawAmountChange = (e) => {
    // Check if the input is a leading zero (e.g., "01" or "001")
    const sanitizedValue = e.target.value.replace(/^-|[^0-9]/g, "");
    if (/^0[0-9]*$/.test(sanitizedValue)) {
      // Remove the leading zero and update the state
      setWithdrawAmount(sanitizedValue.slice(1));
    } else {
      setWithdrawAmount(sanitizedValue);
    }
  };

  const handleUTRChange = (event) => {
    const value = event.target.value;
    //   console.log()
    // Define a regular expression pattern that allows only numbers
    const numberPattern = /^[A-Za-z0-9_-]*$/;

    if (numberPattern.test(value)) {
      setUtrNumber(value);
      setSecurityAmountError("");
    } else {
      setSecurityAmountError("UTR should only contain numbers.");
    }
  };
  const handleDateInputChange = (event) => {
    const newDate = event.target.value;

    // Create a DateTime object from the input value
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    // Check if the input date is valid (i.e., it was successfully parsed)
    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setTransactionDate(inputDateTime.toISODate());
    }
  };
  const handleSubmit = () => {
    if (
      !utrNumber.length ||
      !mid.length ||
      !withdrawAmount.length ||
      !transactionDate.length ||
      !transferType.length
    ) {
      swal({
        title: "Warning",
        text: "Please Fill the Mandatory fields.",
        icon: "warning",
      });
      return;
    }

    let apiPayload = {
      utr: utrNumber,
      mid: mid,
      amount: withdrawAmount,
      transactionDate: transactionDate,
      transferType: transferType,
      virtualAccountType: "",
    };
    if (transferType === "Payout") {
      const selectedPayoutType = document.getElementById("payoutType").value;
      if (selectedPayoutType === "") {
        swal({
          title: "Warning",
          text: "Please select a Virtual Account Type for Payout.",
          icon: "warning",
        });
        return;
      }
      apiPayload.virtualAccountType = selectedPayoutType;
    }

    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/withdrawal/save`,
        apiPayload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          swal({
            title: "Saved",
            text: "Data Saved Successfully.",
            icon: "success",
          });
          setMid("");
          setUtrNumber("");
          setWithdrawAmount("");
          setTransactionDate("");

          setSelectedCompany("");
          setTransferType("");
        } else if (response.data.statusCode === 309) {
          swal({
            title: "Failed",
            text: "Insuffcient Wallet Balance",
            icon: "warning",
          });
        }
        console.log("Data saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  return (
    <>
      {/* {hasPrivilage ? (  */}
        <div className="containerflag">
      {/* <h1 className="mt-3">Select</h1> */}
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <lable>Select Merchant *</lable>
          <select
            id="companyDropdown"
            className="form-control"
            onChange={handleCompanyChange}
            value={selectedCompany}
          >
            <option value="">Select a Company</option>
            {merchantData?.map((merchant) => (
              <option key={merchant.mid} value={`${merchant.prifix} :- ${merchant.merchantName}`}>
              {merchant.prifix} :- {merchant.merchantName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <div className="form-group">
            <label htmlFor="email">Merchant ID</label>
            <input
              required
              type="email"
              className="form-control"
              id="email"
              placeholder="Merchant ID"
              value={mid}
              readOnly
            />
          </div>

          <div className="form-group">
            <label htmlFor="transferType">Transfer Type *</label>
            <select
              required
              className="form-control"
              id="transferType"
              value={transferType}
              onChange={(e) => setTransferType(e.target.value)}
            >
              <option value="">Select Transfer Type</option>
              <option value="Account">Account</option>
              <option value="Payout">Payout</option>
            </select>
          </div>

          {transferType === "Payout" && (
            <div className="form-group">
              <label htmlFor="payoutType">Virtual Account Type *</label>
              <select required className="form-control" id="payoutType">
                <option value="">Account Type</option>
                <option value="UPI">UPI</option>
                <option value="IMPS">IMPS</option>
              </select>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="holdAmount">Withdraw Amount (in Rupees) *</label>
            <div className="d-flex gap-4">
              <input
                required
                type="text"
                className="form-control"
                id="holdAmount"
                placeholder="Withdraw Amount"
                value={withdrawAmount}
                onChange={handleWithdrawAmountChange}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="securityAmount">UTR Number *</label>
            <div className="d-flex gap-4">
              <input
                required
                type="text"
                className="form-control"
                id="securityAmount"
                placeholder="UTR Number*"
                value={utrNumber}
                onChange={handleUTRChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="transactionDate">Transaction Date*</label>
            <div className="d-flex gap-4">
              <input
                required
                type="date"
                className="form-control"
                id="transactionDate"
                placeholder="Transaction Date*"
                value={transactionDate}
                onChange={handleDateInputChange}
              />
            </div>
          </div>

          <div className="button-container">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
      {open && <PopupMessage message={message} open={open} close={setOpen} />}
    </div>
    {/* ) : (<InactiveServices location={"privilages"}/>)} */}
    </>
    
  );
};

export default WithdrawAmount;
