import { config, getCompleteDomain, getServerEnvironment } from "./utils/helper";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import axios from "axios";
import SwipelincLogo from "./assets/images/SwipelincLogo.png";
import LincpayLogo from "./assets/images/LincpayLogo.jpeg";
import user from "./assets/images/user.png";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import { LOGIN_API } from "./utils/constant";
import "./stylesheet/Login.css";
import "./stylesheet/icomoon.css";
import swal from "sweetalert";
import { setTokenToLocalStorage } from "./utils/tokenService";
import { useDispatch } from "react-redux";
import { setPrifix, setPrivilagesData, setType , setMasterId} from "./store/slices/privilagesSlice";
import { jwtDecode } from "jwt-decode";

const schema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  userName: Yup.string().required("Username is required"),
});

const LoginForm = () => {
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
 
  const [error, setError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    setIsUserSignedIn(!!token);
  }, []);

  const dispatch = useDispatch();

  const header = {
    alg: "HS512",
  };

  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));

  const AppNameCapitalized = Name;
  const AppName = AppNameCapitalized.toUpperCase();

  useEffect(() => {
    if (!isUserSignedIn) {
      navigate("/");
    }
  }, [navigate, isUserSignedIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userName || !password) {
      setIsFormSubmitted(true);
      return;
    }

    try {
      await schema.validate({ password, userName }, { abortEarly: false });
      const response = await axios.post(LOGIN_API, { password, userName });

      console.log(response.data.statusCode);
      if (response.data.statusCode === 300) {
        console.log("inside --------------------------------");
        const token = response?.data?.token;
        const privilage = response?.data?.data;
        const {prefix,userType,masterId} = jwtDecode(token);
        console.log(masterId)
         
        console.log(prefix)
        console.log(userType)
        const privilages = privilage?.map(
          (item) => item.adminPrivilegeSubTypeId
        );
     
         dispatch(setPrivilagesData(privilages || []))
         dispatch(setPrifix(prefix))
         dispatch(setType(userType))
         dispatch(setMasterId(masterId))
        localStorage.setItem("token", token);
        navigate("/OnBoarding");
      } else {
        console.log("inside else part --------------------------------");
        setErrorMessages([response.data.status]);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrorMessages(validationErrors);
      } else {
        setError(error.message);
      }
    }
  };
  if (showPopUp) {
    swal({
      title: "ALERT!",
      text: "Your Application Is Under Process!!",
      icon: "warning",
      timer: 2000,
    });
    setShowPopUp(!showPopUp);
  }
  return (
    <section className="swiperlinc_login login_inner">
      <Container>
        <Row>
          <Col xs={12} lg={6} md={6} sm={12} className="login-bg">
            <div class="welcome-login">
              <div class="login-banner px-2">
                <img
                  src={logoPath}
                  className="px-3"
                  alt="logo"
                  class="img-fluid"
                />
              </div>
              <div class="mentor-course text-center">
                <h2>Welcome to {AppName}</h2>
                <p className="text-start">
                  <b className="d-block mb-2"></b>
                  We're excited to welcome you to the {AppNameCapitalized}{" "}
                  family! <br />
                  At {AppNameCapitalized}, we're dedicated to supporting your
                  business growth with our cutting-edge payment gateway
                  services.
                  <br /> real-time insights, and powerful customization tools.
                  Thank you for choosing {AppNameCapitalized} as your payment
                  partner. Your success is our priority, and we're here to
                  assist you every step of the way.
                  <span className="tanks-regards d-block mt-2 fw-700">
                    Best regards, <br />
                    The {AppNameCapitalized} Team
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} md={6} sm={12} className="login-wrap-bg">
            <div class="login-wrapper position-relative">
              <div class="loginbox">
                <div class="w-100">
                  {/* <div class="img-logo">
                                  <div class="back-home merchant-onbording-btn">
                                        <Link to="/EmailForm">
                                            <i class="bi bi-person-fill-add"></i>
                                            <span>Merchant Onboard</span>{" "}
                                        </Link>
                                    </div>
                                </div> */}
                  <div className="mb-md-5 mb-3 login-right-head">
                    <h2 className="title">Login</h2>
                    <p className="mb-0">
                      Welcome back! Please login to your account.
                    </p>
                  </div>
                  {isFormSubmitted && (!userName || !password) && (
                    <div className="error-message alert alert-danger mb-4">
                      Please enter username and password
                    </div>
                  )}
                  {error && (
                    <p className="alert alert-danger mb-4">
                      {error ? "Something Went Wrong" : ""}
                    </p>
                  )}
                  {errorMessages?.map((errorMessage, index) => (
                    <p key={index} className="errorMsg alert alert-danger mb-4">
                      {errorMessage}
                    </p>
                  ))}
                  <form onSubmit={handleSubmit} className="login-right">
                    <div className="form-group">
                      <label className="form-label">Username</label>
                      <span className="icon icon-type-email"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        value={userName}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setuserName(e.target.value)}
                      />
                      {/* {errorMessages?.mid && <div>{errorMessages?.mid}</div>} */}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Password</label>
                      <span className="icon icon-lock"></span>
                      <div className="password-input">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {showPassword ? (
                          <i
                            class="bi bi-eye-fill fs-3 mx-3 mt-1"
                            onClick={() => setShowPassword(!showPassword)}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-eye-slash-fill fs-3 mx-3 mt-1"
                            onClick={() => setShowPassword(!showPassword)}
                          ></i>
                        )}
                      </div>
                      {errorMessages.password && (
                        <div className="alert alert-danger mb-4">
                          {errorMessages.password}
                        </div>
                      )}
                    </div>

                    <div className="row mb-4">
                      <div className="col-sm-6">
                        <div className="mb-md-4 mb-2 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                          />
                          <label
                            className="form-check-label fnt-18 fnt-s"
                            htmlFor="exampleCheck1"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        <div className="mb-md-4 mb-2">
                          <Link
                            to="/ForgotPassword"
                            className="text-red fnt-18 fnt-s"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="login-btn-sec">
                      <button type="submit" className="btn btn-dark login-bt">
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LoginForm;
