import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Button, Col } from "react-bootstrap";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForWithdraw } from "../utils/DownloadHeaders";
import WithdrawAdvanceFilter from "../Merchant/MultipleFilter/WithdrawAdvanceFilter";
import WithdrawMultipleFilter from "../Merchant/MultipleFilter/WithdrawMultipleFilter";
import { getAllDailyTransactionDataWithdrawl } from "../utils/ApiServices";
import MidFiter from "./MidFiter";
import LiveWithdrawlMultipleFilter from "./LiveWithdrawlMultipleFilter";
import LiveWithdrawlAdvanceFilter from "./LiveWithdrawlAdvanceFilter";

function LiveWithdrawlReport({ reportType, setShowLoader, dropDownData }) {
  const [data, setData] = useState();
  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const[currentPage,setCurrentPage] = useState(0) ;
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const entriesPerPageOptions = [5,10, 20, 30,40,50]; 

  const [reset, setReset] = useState(false);
  const [show2, setShow2] = useState(false);
  const navigate = useNavigate();
  

  const getDailyTransactionData = (token,page=0,size=entriesPerPage) => {
    setShowLoader(true)
    const response = getAllDailyTransactionDataWithdrawl(token,page,size)
      .then((response) => {
        if (response.statusCode === 200) {
          console.log("response", response);
          setData(response?.data);
          setShowLoader(false)
        } else {
          setData([]);
          console.error("Error fetching data:", response.data.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };
  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData(token,newPage)
  };
  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      // Reset to first page when changing entries per page
      setCurrentPage(0);
      getDailyTransactionData(token, 0, value);
    }
  };
  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Company Name", field: "fullName", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    { label: "Status", field: "status", width: "auto" },
    // { label: "Name", field: "fullName", width: "auto" },
    { label: "e-Mail Id", field: "emailId", width: "auto" },
    { label: "UTR Number", field: "utr", width: "auto" },
    { label: "Transaction Date", field: "transactionDate", width: "auto" },
    { label: "TransferMode", field: "transferMode", width: "auto" },
    // { label: "Time", field: "createTime", width: "auto" },
    {label: "Virtual Account Number",field: "virtualAccountNumber",width: "auto" },
    {label: "IFSC Code",field: "ifsc",width: "auto" },
    { label: "Bank Name", field: "bankName", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: "Created Date", field: "createDate", width: "auto" },
    { label: "Created Time", field: "createTime", width: "auto" },
  ];

  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      if (column.field === "createDate") {
        const dateValue = item[column.field] ? item[column.field].split(' ')[0] : '';
        mappedItem[column.field] = dateValue;
      } else if (column.field === "createTime") {
        const timeValue = item[column.field] ? item[column.field].split(' ')[1] : '';
        mappedItem[column.field] = timeValue;
      } else if (column.field === "transactionDate") {
        const transactionDate = item[column.field] ? item[column.field].split(' ')[0] : '';
        mappedItem[column.field] = transactionDate;
      } else {
        mappedItem[column.field] = item[column.field];
      }
    });
    return mappedItem;
  });
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleMidFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(true);
  };

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const HandleReset = () => {
    setReset(!reset);
  };
  useEffect(() => {
    getDailyTransactionData(token,currentPage);
  }, [reset,currentPage,entriesPerPage]);

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };
  return (
    <>
      <section className="lincpay-dashboard-sec">
      <div className="d-flex align-items-center">
          <label htmlFor="entriesPerPage">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm" 
            style={{ maxWidth: '100px' }}
          >
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <Col className="d-flex justify-content-end gap-2">
          <Button onClick={handleMidFilter}>Merchant</Button>
          <Button onClick={handleMultipleFilter}>More Filter</Button>
          <Button onClick={handleAdvanceFilter}>Advance Filter</Button>

          <Button
            className="d-flex align-items-center justify-content-center gap-2"
            onClick={HandleReset}
          >
            Reset<i class="bi bi-arrow-clockwise"></i>
          </Button>
        </Col>
        <Card.Body>
          <div className="report-grid-view">
            <div className="material-table-records mb-3">
              <MDBDataTable
                className="dataTable"
                striped
                bordered
                small
                data={{
                  columns: columns,
                  rows: mappedData,
                }}
                searching={true}
                responsive
                sorting={true}
                hover
                footer="none"
                page={currentPage}
                onPageChange={handlePageChange}
                pagination={false}
                paging={false}
              />
            </div>
            <DownloadCsv
              DownLoadData={data}
              // Header={headerLabelsForWithdraw}
              Name={`withdrawl`}
            />
             <div className="pagination-buttons d-flex justify-content-end gap-2">
        <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
          Previous
        </Button>
        <Button onClick={handleNextPage}>Next</Button>
      </div>
          </div>
        </Card.Body>

        <MidFiter
          reportType={reportType}
          setShow2={setShow2}
          show2={show2}
          setLiveData={setData}
        />
        {isAdvanceFilterOpen ? (
        <LiveWithdrawlAdvanceFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setData}
          setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
        />
      ) : isMultipleFilterOpen ? (
        <LiveWithdrawlMultipleFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setData}
          setShowMultipleFilter={setIsMultipleFilterOpen}
          dropDownData={dropDownData}
        />
      ) : (
        ""
      )}
        {/* </Container> */}
      </section>
    </>
  );
}

export default LiveWithdrawlReport;
