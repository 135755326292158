import React, { useEffect, useState } from "react";
import ApplicationSection from "./ApplicationSection";
import Stage2AppSections from "../applicationsstage2/Stage2AppSections";
import InactiveServices from "../components/InactiveServices";

import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SubTabForCreateMerchant from "./SubTabForCreateMerchant";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";
import ApplicationSectionYesBank from "./ApplicationSectionYesBank";
// import MyLoader from "../common/MyLoader";

const SubTabManage = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");
  // const [showLoader, setShowLoader] = useState(false);

  const { privilagesData } = useSelector((state) => state.privilages);

  const hasPrilage2142 = useCheckPrivilages(2142);
  const hasPrilage2595 = useCheckPrivilages(2595);
  const hasPrivilage2630 = useCheckPrivilages(2630);

  useEffect(() => {
    setActivePhase("");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      {/* {showLoader ? <MyLoader/> :""} */}
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        {hasPrilage2142 ? (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Phase 1 {activePhase === "Phase1"}
                          </li>
                        ) : null}
                        {hasPrilage2595 ? (
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Phase 2 {activePhase === "Phase2"}
                          </li>
                        ) : null}
                        {hasPrivilage2630 ? (
                          <li
                            className={activePhase === "Phase3" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase3")}
                          >
                            Create Merchant{activePhase === "Phase3"}
                          </li>
                        ) : null}
                        <li
                            className={activePhase === "Phase4" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase4")}
                          >
                            Yes Bank On-Board Merchant{activePhase === "Phase4"}
                          </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <div className="admin-tab-content">
          
              {activePhase === "Phase1" ? (
                <ApplicationSection setShowLoader={setShowLoader} />
              ) : activePhase === "Phase2" ? (
                <Stage2AppSections setShowLoader={setShowLoader} />
              ) : activePhase === "Phase3" && hasPrivilage2630 ? (
                <SubTabForCreateMerchant setShowLoader={setShowLoader} />
              ) : activePhase === "Phase4" ? (
                <ApplicationSectionYesBank setShowLoader={setShowLoader} />
              ) : (
                null
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SubTabManage;
