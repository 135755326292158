import axios from "axios";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { SWIPELINC_API } from "../../../utils/constant";
import { Container, Card, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import InactiveServices from "../../InactiveServices";

function WalletBalance({setShowLoader}) {
  const [walletBalance, setWalletBalance] = useState([]);
  const [token] = useState(localStorage.getItem("token"));
  const [callAgain, setCallAgain] = useState(false);
 
  const hasPrivilage=useCheckPrivilages(2701)


  const handleApproval = (id) => {
   swal({
        title: "Are you sure?",
        text: "Do you want to approve this Wallet Balance?",
        icon: "warning",
        buttons: ["Cancel", "Yes, approve it!"],
        dangerMode: true,
      }).then((isConfirmed) => {
        if (isConfirmed) {
        
          const axiosInstance = axios.create({
            baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant`,
            headers: {
              Authentication: `Bearer ${token}`,
            },
          });
        
          const payload = {
            id: id,
            remark: "",
            status:"success"
            
          
          };
           setShowLoader(true)
          axiosInstance
            .post("/wallet/update", payload)
            .then((response) => {
              console.log(response)
              if(response.data.statusCode === 200){
                swal("Success", "Wallet Balance approved!", "success")
               setShowLoader(false)
               setCallAgain(!callAgain);

              }else if(response.data.statusCode === 600){
                swal("Failed", `${response.data.message }`, "error")
                }
                else{
                    swal("Failed", `${response.data.message }`, "error")
                }
              setShowLoader(false)
              
               
        })
            .catch((error) => {
              console.error("Error approving the balance:", error.message);
              setShowLoader(false)
            });
        } 
        else {
          
          
        }
      });
    };
  
  const handleReject = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this Wallet Balance Request?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
        const axiosInstance = axios.create({
          baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant`,
          headers: {
            Authentication: `Bearer ${token}`,
          },
        });

        const payload = {
        status: "failed",
        remark: remark,
        id: id,
          
        }
        
         setShowLoader(true)
        axiosInstance
          .post("/wallet/update", payload)
          .then((response) => {
            if(response.data.statusCode === 200){
              swal("Success", "Wallet Balance Rejected!", "success")
             
              setShowLoader(false)
              setCallAgain(!callAgain);
            }
            else if(response.data.statusCode === 600){
                swal("Failed", `${response.data.message }`, "error")
                }
                else{
                    swal("Failed", `${response.data.message }`, "error")
                }
        setShowLoader(false)
           })
       
          .catch((error) => {
            console.error("Error rejecting the application:", error.message);
            setShowLoader(false)
          });
      }
      else {
        
      }
    })
  }

useEffect(() => {
    setShowLoader(true)
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/wallet/status/pending`, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
            setWalletBalance(response.data.data);
        } else {
            setWalletBalance([]);
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching wallet data:", error);
        setShowLoader(false)
      });
  }, [callAgain]);

  const columns = [
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Status", field: "status", width: "auto" },
    {label:"Company Name", field:"companyName",width:"auto"},
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "UTR", field: "utr", width: "auto" },
    { label: "Mode Of Transfer", field: "transferMode", width: "auto" },
    { label: "Created Time", field: "createTime", width: "auto" },
    { label: "Created Date", field: "createDate", width: "auto" },
    { label: "Action", field: "action", width: "auto" },
  ];

  const mappedData = walletBalance?.map((item) => {
    return {
      ...item,
      action: (
        <div className="d-flex gap-2">
          <button
            className="btn btn-success"
            onClick={() => {
              
              handleApproval((item.id));
            }}
          >
            Approve
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              
                handleReject((item.id));
            }}
          >
            Reject
          </button>
        </div>
      ),
    };
  });
 
  return (
    <> 
    {/* {hasPrivilage ? (  */}
      <Container>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                  <MDBDataTable
                    className="dataTable"
                    hover
                    striped
                    small
                    bordered
                    responsive
                    sortable={true}
                    searching={true}
                    entries={10}
                    data={{
                      columns: columns,
                      rows: mappedData,
                    }}
                    footer="none"
                    theadColor="#333"
                    paginationLabel={["Previous", "Next"]}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    {/* ) : (<InactiveServices location={"privilages"}/>)} */}
    </>
  );
}

export default WalletBalance;